import { useState, useEffect } from "react";
import { CommonLoading } from "../../../components/Loading/Loading";
import TbTPage from "../../../components/TbTPage/TbTPage";
import { IDualMeet, IDualMeet_DB, ITeam, UserFlag } from "../../../types";

import "./DualMeets.css";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DualMeetInfoComponent from "../../../components/DualMeetInfo";
import useDatabase from "../../../hooks/database";
import StyledDropdown from "../../../components/StyledDropdown/StyledDropdown";
import { MenuItem, TextField } from "@mui/material";
import fire from "../../../utils/firebaseSetup";
import { DBResult, isSuccess } from "../../../utils/database";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../utils/store";
import ErrorPage from "../NotFound/NotFound";
import { CURRENT_SEASON_STR } from "../../../utils/season";

export default function DualMeets() {
    const DB = useDatabase();
    const userInfo = useSelector((s: ReduxState) => s.userInfo);

    const [dualMeetsRaw, setDualMeetsRaw] = useState<IDualMeet_DB[]>([]);
    const [loading, setLoading] = useState(false);
    const [selectedSeason, setSelectedSeason] = useState(CURRENT_SEASON_STR);
    const [meetFilter, setMeetFilter] = useState("");

    const [criticalError, setCriticalError] = useState("");

    const sort = (a: IDualMeet, b: IDualMeet) => b.startedAt! - a.startedAt!;

    const handleDualMeets = async (v: DBResult<Record<string, IDualMeet_DB>>) => {
        if (v.status === "fail") return setCriticalError(v.data);

        const values = Object.values(v.data).sort(sort);
        const linkedFencers = userInfo ? await Promise.all(userInfo.linkedFencerIds.map(l => DB.getFencer(l))) : [];
        const linkedTeams = linkedFencers.filter(isSuccess).flatMap(l => l.data.teams);
        if ((userInfo?.flags || 0) & UserFlag.MeetManager) {
            setDualMeetsRaw(values);
        } else {
            const teams = userInfo?.teams || [];
            // TODO: Check for managers as well
            setDualMeetsRaw(
                values.filter(l => l.published || teams.some(j => [l.team1ID || "asdf", l.team2ID || "asdf", ...linkedTeams].includes(j)))
            );
        }
        setLoading(false);
        console.timeEnd("getting meets");
    };

    useEffect(() => {
        setLoading(true);

        console.time("getting meets");
        DB.getDualMeets(handleDualMeets);

        return () => {
            DB.stopListeningDualMeetsRaw(handleDualMeets);
        };
    }, []);

    if (criticalError) {
        return <ErrorPage message={criticalError} />;
    }

    let dualMeetDays: (IDualMeet | IDualMeet_DB)[][] = [];
    const dualMeetDaysObj: Record<string, (IDualMeet | IDualMeet_DB)[]> = {};

    const filteredMeets = dualMeetsRaw
        .filter(l => l.season === selectedSeason)
        .filter(l => l.name.toLowerCase().includes(meetFilter.toLowerCase()));

    for (const meet of filteredMeets) {
        const d = new Date(meet.startedAt).toDateString();
        if (d in dualMeetDaysObj) {
            dualMeetDaysObj[d].push(meet);
        } else {
            dualMeetDaysObj[d] = [meet];
        }
        dualMeetDays = Object.values(dualMeetDaysObj);
        dualMeetDays.sort((a, b) => b[0].startedAt - a[0].startedAt);
    }

    return (
        <TbTPage>
            <div style={{ height: 50 }}></div>
            <Typography fontFamily="Lexend Deca" component="h1" variant="h3" sx={{ marginBottom: "10px" }}>
                Dual Meets
            </Typography>
            <div
                style={{
                    display: "flex",
                    marginTop: "10px",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <TextField
                    label="Search"
                    placeholder="Enter a meet name..."
                    variant="outlined"
                    sx={{ marginRight: "10px" }}
                    value={meetFilter}
                    onChange={e => setMeetFilter(e.target.value)}
                />
            </div>
            <br />
            {loading ? (
                <CommonLoading />
            ) : (
                <Stack spacing={2}>
                    {dualMeetDays.map(day => (
                        <div key={`dfi${day[0].startedAt.toLocaleString()}`}>
                            <Grid
                                key={`dafioj${day[0].startedAt.toLocaleString()}`}
                                container
                                spacing={3}
                                style={{
                                    width: "90vw",
                                    margin: "auto",
                                    paddingRight: "24px"
                                }}
                            >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography
                                        key={`asdfij${day[0].startedAt.toLocaleString()}`}
                                        fontFamily={"Lexend Deca"}
                                        variant="h4"
                                        textAlign="left"
                                    >
                                        {new Date(day[0].startedAt).toDateString()}
                                    </Typography>
                                </Grid>
                                {day
                                    .filter(l => l.season === selectedSeason)
                                    .map(l => (
                                        <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={`gridItem${l.name}${l.createdAt}`}>
                                            <DualMeetInfoComponent data={l} key={`dualMeetListBox${l.id}`} />
                                        </Grid>
                                    ))}
                            </Grid>
                        </div>
                    ))}
                </Stack>
            )}
        </TbTPage>
    );
}
