import { useState } from "react";
import fire from "../../../utils/firebaseSetup";
import { useHistory } from "react-router-dom";
import CarryingMask from "../../../assets/CarryingMask.jpg";
import TbtIcon from "../../../assets/TBTIconNew.png";
import ForgotLogin from "../../../components/ForgotLogin";
import TbTPage from "../../../components/TbTPage/TbTPage";
import { UserFlag } from "../../../types";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import { Box, Card, CardContent, CardHeader, CardMedia, Modal } from "@mui/material";
import "./LoginFront.css";
import useDatabase from "../../../hooks/database";

const Login = () => {
    const DB = useDatabase();

    const history = useHistory();

    //states for user info
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    //states for email and password errors
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");

    //state to switch between Sign In, and Register
    const [hasAccount, setHasAccount] = useState(true);
    const [forgotPassword, setForgotPassword] = useState(false);

    const clearErrors = () => {
        setEmailError("");
        setPasswordError("");
    };
    const postLogin = async () => {
        const userInfo = await DB.getCurrentUserInfo();
        if (userInfo.status === "fail") return;
        const userInfoV2 = userInfo.data;
        if (userInfoV2) {
            const flags = userInfoV2.flags || 0;
            if (flags & UserFlag.BoutCommittee) {
                history.push("/legacy/tournaments");
            } else if (flags & UserFlag.UberAdmin) {
                history.push("/teamList");
            } else if (userInfoV2.teams.length) {
                history.push("/teamList");
            } else {
                history.push("/");
            }
        }
    };

    const handleLogin = async () => {
        clearErrors();
        try {
            await fire.auth().signInWithEmailAndPassword(email, password);
            postLogin();
        } catch (err: any) {
            switch (err.code) {
                case "auth/invalid-email":
                case "auth/user-disabled":
                case "auth/user-not-found":
                    setEmailError(err.message);
                    break;
                default:
                    setPasswordError(err.message);
                    break;
            }
        }
    };

    const handleSignup = async () => {
        clearErrors();
        try {
            const { user } = await fire.auth().createUserWithEmailAndPassword(email, password);
            if (user) {
                await DB.createUser(user.uid, firstName, lastName, email);
                history.push("/createSchool");
            }
        } catch (err: any) {
            switch (err.code) {
                case "auth/email-already-in-use":
                case "auth/invalid-email":
                    setEmailError(err.message);
                    break;
                default:
                    setPasswordError(err.message);
                    break;
            }
        }
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        hasAccount ? handleLogin() : handleSignup();
    };

    const canSubmit = (hasAccount || (firstName.trim() !== "" && lastName.trim() !== "")) && email.trim() !== "" && password.trim() !== "";

    if (forgotPassword) {
        return (
            <ForgotLogin
                email={email}
                setEmail={setEmail}
                emailError={emailError}
                setEmailError={setEmailError}
                setForgotPassword={setForgotPassword}
            />
        );
    }

    return (
        <TbTPage>
            <div style={{ height: "10vh" }} />
            <Card
                sx={{
                    display: "flex",
                    width: "90vw",
                    maxWidth: "900px",
                    margin: "0 auto"
                }}
            >
                <Box>
                    <CardHeader title={hasAccount ? "Log In" : "Register"} titleTypographyProps={{ fontFamily: "Lexend Deca" }} />
                    <CardContent
                        sx={{
                            padding: "10px !important",
                            width: "300px",
                            flexShrink: "0"
                        }}
                    >
                        <Box component="form" onSubmit={handleSubmit}>
                            <img src={TbtIcon} alt="TBT Icon" style={{ width: 60, height: 60 }} />
                            {!hasAccount && (
                                <>
                                    <FormControl style={{ marginTop: 10, width: "90%" }}>
                                        <TextField
                                            label="First Name"
                                            placeholder="First Name"
                                            size="small"
                                            value={firstName}
                                            onChange={e => setFirstName(e.target.value)}
                                            fullWidth
                                        />
                                    </FormControl>
                                    <FormControl style={{ marginTop: 10, width: "90%" }}>
                                        <TextField
                                            label="Last Name"
                                            placeholder="Last Name"
                                            size="small"
                                            value={lastName}
                                            onChange={e => setLastName(e.target.value)}
                                            fullWidth
                                        />
                                    </FormControl>
                                </>
                            )}
                            <FormControl style={{ marginTop: 10, width: "90%" }}>
                                <TextField
                                    label="Email"
                                    placeholder="Email"
                                    type="email"
                                    size="small"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    autoComplete="email"
                                    fullWidth
                                    autoFocus
                                />
                                <FormHelperText
                                    style={{
                                        width: "100%",
                                        color: "red",
                                        display: "flex",
                                        textAlign: "left"
                                    }}
                                >
                                    {emailError}
                                </FormHelperText>
                            </FormControl>
                            <FormControl style={{ margin: 10, width: "90%" }}>
                                <TextField
                                    label="Password"
                                    placeholder="Password"
                                    type="password"
                                    size="small"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    autoComplete="password"
                                    fullWidth
                                />
                                <FormHelperText
                                    style={{
                                        width: "100%",
                                        color: "red",
                                        display: "flex",
                                        textAlign: "left"
                                    }}
                                >
                                    {passwordError}
                                </FormHelperText>
                            </FormControl>
                            <Button variant="contained" type="submit" disabled={!canSubmit} style={{ width: "90%", margin: "5px 0" }}>
                                {hasAccount ? "Sign in" : "Sign up"}
                            </Button>
                            {hasAccount ? (
                                <>
                                    <Button
                                        variant="contained"
                                        className="forgotPassword"
                                        onClick={() => setForgotPassword(true)}
                                        color="secondary"
                                        style={{ width: "90%", margin: "5px 0 10px 0" }}
                                    >
                                        Forgot Password?
                                    </Button>
                                </>
                            ) : null}
                            <p style={{ fontSize: 15 }}>
                                {hasAccount ? "Don't have" : "Have"} an account?
                                <span className="switchSignIn" onClick={() => setHasAccount(!hasAccount)}>
                                    {" "}
                                    Sign {hasAccount ? "Up" : "In"}
                                </span>
                            </p>
                        </Box>
                    </CardContent>
                </Box>
                <CardMedia sx={{ maxWidth: "600px" }} component="img" image={CarryingMask} alt="Fencer carrying a mask" />
            </Card>
        </TbTPage>
    );
};

export default Login;
