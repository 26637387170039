import "./About.css";
import TbTPage from "../../../components/TbTPage/TbTPage";
import RedPandaFull from "../../../assets/RedPandaFull.png";
import GooglePlayBadge from "../../../assets/google-play-badge.png";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { CardMedia, createTheme, ThemeProvider, Typography } from "@mui/material";

import AppStore from "../../../assets/AppStore.png";

const lightTheme = createTheme({
    palette: {
        mode: "light"
    }
});

export default function About() {
    return (
        <TbTPage className="aboutPage">
            <Card sx={{ width: "90vw", maxWidth: 600, margin: "auto" }}>
                <CardHeader
                    title="TouchByTouch"
                    titleTypographyProps={{
                        fontFamily: "Lexend Deca",
                        fontSize: 30
                    }}
                />
                <CardContent sx={{ padding: "5px !important" }}>
                    <Typography letterSpacing={0.5} variant="h6">
                        Developed by fencers
                    </Typography>
                    <Typography letterSpacing={0.5} variant="subtitle1">
                        Varun Gupta (Creator)
                    </Typography>
                    <Typography letterSpacing={0.5} variant="subtitle1">
                        Andrew 'Lefty' Zlotnick (Co-Creator)
                    </Typography>
                    <Typography letterSpacing={0.5} variant="subtitle1">
                        Brandon Howe (Developer)
                    </Typography>
                    <Typography letterSpacing={0.5} variant="subtitle1">
                        MacKenn Bross (Developer)
                    </Typography>
                    <Typography letterSpacing={0.5} variant="subtitle1">
                        Henry 'Denny' Hill (Advisor)
                    </Typography>
                    <Typography letterSpacing={0.5} variant="subtitle1">
                        Jay Lefever (Advisor)
                    </Typography>
                </CardContent>
            </Card>
            <div className="appDownloadsV2">
                <a href="https://apps.apple.com/us/app/touch-by-touch-college-fencing/id6451499719">
                    <img src={AppStore} alt="Download on App Store" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=live.touchbytouchcollege">
                    <img src={GooglePlayBadge} alt="Download on Google Play" />
                </a>
            </div>
            <div className="userGuidesV2">
                <a target="_blank" rel="noreferrer" href="/privacy">
                    <div className="userGuide">Privacy policy</div>
                </a>
            </div>
            <div className="aboutLowerSectionV2">
                <ThemeProvider theme={lightTheme}>
                    <Card sx={{ display: "inline-block", margin: "10px auto" }}>
                        <CardMedia component="img" image={RedPandaFull} alt="Red Panda Fencing Inc. logo" />
                        <CardContent sx={{ padding: "10px 16px !important" }}>
                            <Typography variant="h6">SPONSORED BY RED PANDA FENCING INC.</Typography>
                            <Typography variant="subtitle1">Version 2.0.0</Typography>
                        </CardContent>
                    </Card>
                </ThemeProvider>
                <h6 className="stockNotice">Stock photos licensed under iStockPhoto.com inv # 15376813, cust # 20742485</h6>
            </div>
        </TbTPage>
    );
}
