import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Modal,
    Paper,
    Radio,
    Select,
    Step,
    StepLabel,
    Stepper,
    Tab,
    Tabs,
    TextField,
    ToggleButton,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import TbTPage from "../../../components/TbTPage/TbTPage";

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CollegeProgramType, ICollegeEventTeam, IOrganization, ITeam, SchoolType, UserFlag } from "../../../types";
import useDatabase from "../../../hooks/database";
import { mdiChevronDown, mdiChevronLeft, mdiChevronRight, mdiChevronUp, mdiCog, mdiDelete, mdiSwapHorizontal } from "@mdi/js";
import "./EventCreation.css";
import Icon from "@mdi/react";
import { modalBoxStyle, modalBoxStyleNarrower } from "../../..";
import { GenTournamentOrder, TournamentOrderArrs, genAbbreviationFromName, genOrderArr } from "../../../utils/helpers";
import { CommonLoading } from "../../../components/Loading/Loading";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { NCAADivisions } from "../../../utils/ncaaConference";
import { CreateMeetCard } from "../../../components/EventSchedule";
import { ReduxState } from "../../../utils/store";
import { useSelector } from "react-redux";
import PermissionDenied from "../../../components/PermissionDenied/PermissionDenied";
import ErrorPage from "../NotFound/NotFound";

enum EventCreationSteps {
    ChooseSchools = 0,
    CreateRounds = 1,
    EventDetails = 2,
    Complete = 3
}

type WriteInTeam = {
    name: string;
    abbreviation: string;
    region: string;
    id: string;
    orgID: string;
};

type WriteInOrganization = {
    name: string;
    abbreviation: string;
    id: string;
    division: string;
    boysTeam?: string;
    girlsTeam?: string;
    boysJVTeam?: string;
    girlsJVTeam?: string;
};

type TeamOption = ITeam | WriteInTeam;

type OrganizationSettings = {
    data: IOrganization | WriteInOrganization;
    boysTeamActive: boolean;
    girlsTeamActive: boolean;
    boysJVTeamActive: boolean;
    girlsJVTeamActive: boolean;
    isHostSchool: boolean;
};

interface Meet {
    a: TeamOption;
    b: TeamOption;
}

interface Round {
    name: string;
    meets: Meet[];
    byes: TeamOption[];
}

interface EventDetailsProps {
    eventName: string;
    eventLocation: string;
    eventAddress: string;
    setEventName: (val: string) => void;
    setEventLocation: (val: string) => void;
    setEventAddress: (val: string) => void;
    startDate: Date;
    setStartDate: (val: Date) => void;
}

interface OrganizationInChooseListProps {
    data: OrganizationSettings;
    setMensUpdateKey: React.Dispatch<React.SetStateAction<number>>;
    setWomensUpdateKey: React.Dispatch<React.SetStateAction<number>>;
    setEditingSchool: React.Dispatch<React.SetStateAction<OrganizationSettings>>;
}

const OrganizationInChooseList = ({ data, setMensUpdateKey, setWomensUpdateKey, setEditingSchool }: OrganizationInChooseListProps) => {
    const [localUpdateKey, setLocalUpdateKey] = useState(0);

    const onToggle = (school: OrganizationSettings) => {
        if (school.boysTeamActive || school.girlsTeamActive || school.boysJVTeamActive || school.girlsJVTeamActive) {
            if (school.boysTeamActive || school.boysJVTeamActive) setMensUpdateKey(u => u + 1);
            if (school.girlsTeamActive || school.girlsJVTeamActive) setWomensUpdateKey(u => u + 1);
            school.boysTeamActive = school.girlsTeamActive = school.boysJVTeamActive = school.girlsJVTeamActive = false;
        } else {
            if (school.data.boysTeam) {
                school.boysTeamActive = true;
                setMensUpdateKey(u => u + 1);
            }
            if (school.data.girlsTeam) {
                school.girlsTeamActive = true;
                setWomensUpdateKey(u => u + 1);
            }
        }
        setLocalUpdateKey(u => u + 1);
    };

    const key = "administrators" in data.data ? data.data.id : data.data.name;

    return (
        <ListItem
            key={`${key}${localUpdateKey}${data.boysTeamActive}${data.girlsTeamActive}${data.boysJVTeamActive}${data.girlsJVTeamActive}`}
            disablePadding
            secondaryAction={
                <IconButton edge="end" aria-label="settings" onClick={() => setEditingSchool(data)}>
                    <Icon path={mdiCog} size={1} horizontal vertical rotate={180} />
                </IconButton>
            }
        >
            <ListItemButton onClick={() => onToggle(data)} dense>
                <ListItemIcon style={{ marginRight: -20 }}>
                    <Checkbox
                        edge="start"
                        checked={data.boysTeamActive || data.girlsTeamActive || data.boysJVTeamActive || data.girlsJVTeamActive}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                            "aria-labelledby": `checkbox-list-label-${key}`
                        }}
                    />
                </ListItemIcon>
                <ListItemText id={`checkbox-list-label-${key}`} primary={data.data.name} />
            </ListItemButton>
        </ListItem>
    );
};

interface TeamInChooseListProps {
    data: TeamOption;
    organizations: OrganizationSettings[];
    setMensUpdateKey: React.Dispatch<React.SetStateAction<number>>;
    setWomensUpdateKey: React.Dispatch<React.SetStateAction<number>>;
}

const TeamInChooseList = ({ data, organizations, setMensUpdateKey, setWomensUpdateKey }: TeamInChooseListProps) => {
    const deleteTeam = () => {
        const matchingOrg = organizations.find(
            l =>
                l.data.boysTeam === data.id ||
                l.data.girlsTeam === data.id ||
                l.data.boysJVTeam === data.id ||
                l.data.girlsJVTeam === data.id
        );

        if (!matchingOrg) return;

        if (matchingOrg.data.boysTeam === data.id) {
            matchingOrg.boysTeamActive = false;
            setMensUpdateKey(u => u + 1);
        }
        if (matchingOrg.data.girlsTeam === data.id) {
            matchingOrg.girlsTeamActive = false;
            setWomensUpdateKey(u => u + 1);
        }
        if (matchingOrg.data.boysJVTeam === data.id) {
            matchingOrg.boysJVTeamActive = false;
            setMensUpdateKey(u => u + 1);
        }
        if (matchingOrg.data.girlsJVTeam === data.id) {
            matchingOrg.girlsJVTeamActive = false;
            setWomensUpdateKey(u => u + 1);
        }
    };

    return (
        <ListItem
            key={`teamInList${data.id}`}
            style={{ height: 60 }}
            secondaryAction={
                <IconButton edge="end" aria-label="settings" onClick={() => deleteTeam()}>
                    <Icon path={mdiDelete} size={1} horizontal vertical rotate={180} />
                </IconButton>
            }
        >
            <ListItemText primary={data.name} />
        </ListItem>
    );
};

interface ChooseSchoolsProps {
    organizations: OrganizationSettings[];
    mensTeams: TeamOption[];
    womensTeams: TeamOption[];
    setMensUpdateKey: React.Dispatch<React.SetStateAction<number>>;
    setWomensUpdateKey: React.Dispatch<React.SetStateAction<number>>;
    setEditingSchool: React.Dispatch<React.SetStateAction<OrganizationSettings>>;
    setAddingWriteIn: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChooseSchools = ({
    organizations,
    mensTeams,
    womensTeams,
    setMensUpdateKey,
    setWomensUpdateKey,
    setEditingSchool,
    setAddingWriteIn
}: ChooseSchoolsProps) => {
    const host: OrganizationSettings = organizations.find((l: OrganizationSettings) => l.isHostSchool)!;

    return (
        <Box
            style={{
                display: "flex",
                height: 500,
                justifyContent: "space-between"
            }}
        >
            <Box
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "30%"
                }}
            >
                <Typography variant="h6" style={{ textAlign: "center", paddingTop: 10 }}>
                    All schools
                </Typography>
                <Tooltip title="Change the host by opening a school's settings">
                    <Typography
                        variant="h6"
                        style={{
                            textAlign: "center",
                            paddingTop: 10,
                            fontSize: 16
                        }}
                    >
                        {host ? `Host: ${host.data.name}` : `No host school set`}
                    </Typography>
                </Tooltip>
                <Button
                    onClick={() => setAddingWriteIn(true)}
                    variant="outlined"
                    style={{
                        width: "calc(100% - 30px)",
                        margin: "10px 15px 0 15px"
                    }}
                >
                    Add write-in
                </Button>
                <List style={{ maxHeight: "100%", overflowY: "auto" }}>
                    {organizations.map(l => (
                        <OrganizationInChooseList
                            data={l}
                            key={`orgInChooseList${l.data.id}`}
                            setMensUpdateKey={setMensUpdateKey}
                            setWomensUpdateKey={setWomensUpdateKey}
                            setEditingSchool={setEditingSchool}
                        />
                    ))}
                </List>
            </Box>
            <Box
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "30%"
                }}
            >
                <Typography variant="h6" style={{ textAlign: "center", paddingTop: 10 }}>
                    Added men's teams ({mensTeams.length})
                </Typography>
                <List style={{ maxHeight: "100%", overflowY: "auto" }}>
                    {mensTeams.map(l => (
                        <TeamInChooseList
                            data={l}
                            key={`teamInMensList${l.id}`}
                            organizations={organizations}
                            setMensUpdateKey={setMensUpdateKey}
                            setWomensUpdateKey={setWomensUpdateKey}
                        />
                    ))}
                </List>
            </Box>
            <Box
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "30%"
                }}
            >
                <Typography variant="h6" style={{ textAlign: "center", paddingTop: 10 }}>
                    Added women's teams ({womensTeams.length})
                </Typography>
                <List style={{ maxHeight: "100%", overflowY: "auto" }}>
                    {womensTeams.map(l => (
                        <TeamInChooseList
                            data={l}
                            key={`teamInWomensList${l.id}`}
                            organizations={organizations}
                            setMensUpdateKey={setMensUpdateKey}
                            setWomensUpdateKey={setWomensUpdateKey}
                        />
                    ))}
                </List>
            </Box>
        </Box>
    );
};

interface MeetListProps {
    items: Meet[];
    setItems: React.Dispatch<React.SetStateAction<Meet[]>>;
    setMeetsChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

const MeetList = ({ items, setItems, setMeetsChanged }: MeetListProps) => {
    const deleteMeetFromList = (item: Meet) => {
        setItems(u => u.filter(l => l !== item));
        setMeetsChanged(true);
    };

    return (
        <Box
            style={{
                padding: 8,
                height: 350,
                overflowY: "auto"
            }}
        >
            {items.map(item => (
                <Paper elevation={3} style={{ marginBottom: 10, height: 70 }} key={`meetInList${item.a.id}${item.b.id}`}>
                    <Box
                        style={{
                            padding: 10,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%"
                        }}
                    >
                        <Typography className="meetListText" style={{ width: "40%" }}>
                            {item.a.name}
                        </Typography>
                        <Typography className="meetListText" style={{ width: "10%", color: "#777" }}>
                            vs.
                        </Typography>
                        <Typography className="meetListText" style={{ width: "40%" }}>
                            {item.b.name}
                        </Typography>
                        <IconButton edge="end" style={{ width: "10%" }} onClick={() => deleteMeetFromList(item)}>
                            <Icon path={mdiDelete} size={1} horizontal vertical rotate={180} />
                        </IconButton>
                    </Box>
                </Paper>
            ))}
        </Box>
    );
};

interface GenerateMeetOrderProps {
    generatedMensMeets: Meet[];
    generatedWomensMeets: Meet[];
    setGeneratedMensMeets: React.Dispatch<React.SetStateAction<Meet[]>>;
    setGeneratedWomensMeets: React.Dispatch<React.SetStateAction<Meet[]>>;
    meetsChanged: boolean;
    setMeetsChanged: React.Dispatch<React.SetStateAction<boolean>>;
    resetMeets: () => void;
}

const GenerateMeetOrder = ({
    generatedMensMeets,
    generatedWomensMeets,
    setGeneratedMensMeets,
    setGeneratedWomensMeets,
    meetsChanged,
    setMeetsChanged,
    resetMeets
}: GenerateMeetOrderProps) => {
    return (
        <Box className="stepBox">
            <Button variant="contained" style={{ marginTop: 20 }} disabled={!meetsChanged} onClick={() => resetMeets()}>
                Reset meets
            </Button>
            <Typography
                variant="body1"
                style={{
                    textAlign: "center",
                    userSelect: "none",
                    marginTop: 10,
                    marginBottom: 10
                }}
            >
                Check the generated meets list to ensure all necessary meets have been created.
            </Typography>
            <Box
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    marginBottom: 10
                }}
            >
                <Typography
                    variant="h6"
                    style={{
                        textAlign: "center",
                        width: "50%",
                        userSelect: "none"
                    }}
                >
                    Men's meet order ({generatedMensMeets.length} meets)
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        textAlign: "center",
                        width: "50%",
                        userSelect: "none"
                    }}
                >
                    Women's meet order ({generatedWomensMeets.length} meets)
                </Typography>
            </Box>
            <Box
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-around"
                }}
            >
                <Box style={{ width: "45%", overflowY: "auto" }}>
                    <MeetList items={generatedMensMeets} setItems={setGeneratedMensMeets} setMeetsChanged={setMeetsChanged} />
                </Box>
                <Box style={{ width: "45%", overflowY: "auto" }}>
                    <MeetList items={generatedWomensMeets} setItems={setGeneratedWomensMeets} setMeetsChanged={setMeetsChanged} />
                </Box>
            </Box>
        </Box>
    );
};

const EventDetails = ({
    eventName,
    setEventName,
    eventLocation,
    setEventLocation,
    eventAddress,
    setEventAddress,
    startDate,
    setStartDate
}: EventDetailsProps) => {
    return (
        <Box className="stepBox">
            <Typography variant="h6" style={{ margin: 10 }}>
                Event details
            </Typography>
            <TextField
                className="eventDetailsInput"
                key="eventName"
                label="Event Name"
                placeholder="Event Name"
                value={eventName}
                onChange={e => setEventName(e.target.value)}
            />
            <TextField
                className="eventDetailsInput"
                label="Event Location"
                placeholder="Event Location"
                value={eventLocation}
                onChange={e => setEventLocation(e.target.value)}
            />
            <TextField
                className="eventDetailsInput"
                label="Event Address"
                placeholder="Event Address"
                value={eventAddress}
                onChange={e => setEventAddress(e.target.value)}
            />
            <DateTimePicker
                renderInput={props => <TextField className="eventDetailsInput" {...props} />}
                desktopModeMediaQuery="@media only screen and (min-width: 600px)"
                label="Meet Start Date"
                value={startDate}
                onChange={setStartDate}
            />
        </Box>
    );
};

interface AddingWriteInModalProps {
    setAddingWriteIn: React.Dispatch<React.SetStateAction<boolean>>;
    addWriteIn: (
        name: string,
        abbreviation: string,
        boysTeam: CollegeProgramType,
        girlsTeam: CollegeProgramType,
        boysJVTeam: boolean,
        girlsJVTeam: boolean,
        division: string
    ) => void;
}

const AddingWriteInModal = ({ setAddingWriteIn, addWriteIn }: AddingWriteInModalProps) => {
    const defaultConference = { name: "Select division", id: "select" };

    const [name, setName] = useState("");
    const [abbreviation, setAbbreviation] = useState("");
    const [boysTeam, setBoysTeam] = useState(CollegeProgramType.None);
    const [girlsTeam, setGirlsTeam] = useState(CollegeProgramType.None);
    const [boysJV, setBoysJV] = useState(false);
    const [girlsJV, setGirlsJV] = useState(false);
    const [conference, setConference] = useState(defaultConference);

    const canAdd =
        name.trim() &&
        abbreviation.trim() &&
        (boysTeam !== CollegeProgramType.None || girlsTeam !== CollegeProgramType.None || boysJV || girlsJV);

    return (
        <Box sx={{ ...modalBoxStyleNarrower, maxWidth: 450 }}>
            <Typography variant="h4" fontFamily="Lexend Deca" sx={{ marginBottom: "20px" }}>
                Add write-in school
            </Typography>

            <TextField
                label="School name"
                placeholder="School name"
                fullWidth
                sx={{ marginBottom: "10px" }}
                value={name}
                onChange={e => setName(e.target.value)}
            />

            <TextField
                label="School abbreviation"
                placeholder="School abbreviation"
                fullWidth
                sx={{ marginBottom: "15px" }}
                value={abbreviation}
                onChange={e => setAbbreviation(e.target.value)}
            />

            <FormControl fullWidth sx={{ marginBottom: "15px" }}>
                <InputLabel id="region-select">Division Name</InputLabel>
                <Select
                    labelId="region-select"
                    id="region-select"
                    label="Division Name"
                    value={conference.id}
                    onChange={e => setConference(NCAADivisions.find(l => l.id === e.target.value) || defaultConference)}
                >
                    {NCAADivisions.map(l => (
                        <MenuItem value={l.id} key={`newTeamConference${l.id}`}>
                            {l.name || "Select division"}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Grid container columns={3}>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}>
                    <Typography textAlign="center">Men's</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography textAlign="center">Women's</Typography>
                </Grid>
                <Grid
                    item
                    xs={1}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <Typography>Varsity</Typography>
                </Grid>
                <Grid item xs={1} sx={{ textAlign: "center" }}>
                    <Radio checked={boysTeam === CollegeProgramType.Varsity} onChange={() => setBoysTeam(CollegeProgramType.Varsity)} />
                </Grid>
                <Grid item xs={1} sx={{ textAlign: "center" }}>
                    <Radio checked={girlsTeam === CollegeProgramType.Varsity} onChange={() => setGirlsTeam(CollegeProgramType.Varsity)} />
                </Grid>
                <Grid
                    item
                    xs={1}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <Typography>Club</Typography>
                </Grid>
                <Grid item xs={1} sx={{ textAlign: "center" }}>
                    <Radio checked={boysTeam === CollegeProgramType.Club} onChange={() => setBoysTeam(CollegeProgramType.Club)} />
                </Grid>
                <Grid item xs={1} sx={{ textAlign: "center" }}>
                    <Radio checked={girlsTeam === CollegeProgramType.Club} onChange={() => setGirlsTeam(CollegeProgramType.Club)} />
                </Grid>
                <Grid
                    item
                    xs={1}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <Typography>JV</Typography>
                </Grid>
                <Grid item xs={1} sx={{ textAlign: "center" }}>
                    <Checkbox disabled={boysTeam === CollegeProgramType.None} checked={boysJV} onChange={() => setBoysJV(u => !u)} />
                </Grid>
                <Grid item xs={1} sx={{ textAlign: "center" }}>
                    <Checkbox disabled={girlsTeam === CollegeProgramType.None} checked={girlsJV} onChange={() => setGirlsJV(u => !u)} />
                </Grid>
                <Grid
                    item
                    xs={1}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <Typography>None</Typography>
                </Grid>
                <Grid item xs={1} sx={{ textAlign: "center" }}>
                    <Radio
                        checked={boysTeam === CollegeProgramType.None}
                        onChange={() => {
                            setBoysTeam(CollegeProgramType.None);
                            setBoysJV(false);
                        }}
                    />
                </Grid>
                <Grid item xs={1} sx={{ textAlign: "center" }}>
                    <Radio
                        checked={girlsTeam === CollegeProgramType.None}
                        onChange={() => {
                            setGirlsTeam(CollegeProgramType.None);
                            setGirlsJV(false);
                        }}
                    />
                </Grid>
            </Grid>

            <Box
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "end",
                    marginTop: "10px"
                }}
            >
                <Button variant="outlined" sx={{ marginRight: "10px" }} onClick={() => setAddingWriteIn(false)}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    disabled={!canAdd}
                    onClick={() => addWriteIn(name.trim(), abbreviation.trim(), boysTeam, girlsTeam, boysJV, girlsJV, conference.id)}
                >
                    Add
                </Button>
            </Box>
        </Box>
    );
};

const AddingMeetOption = ({
    meet,
    invalid,
    selectedMeets,
    setSelectedMeets
}: {
    meet: Meet;
    invalid?: boolean;
    selectedMeets: Set<string>;
    setSelectedMeets: React.Dispatch<React.SetStateAction<Set<string>>>;
}) => {
    const shortenedNameA = meet.a.name.replace(/ Men('|’)s/g, "").replace(/ Women('|’)s/g, "");
    const shortenedNameB = meet.b.name.replace(/ Men('|’)s/g, "").replace(/ Women('|’)s/g, "");

    const key = `${meet.a.id}|${meet.b.id}`;
    const isSelected = selectedMeets.has(key);

    const toggle = () => {
        if (!isSelected) {
            setSelectedMeets(u => new Set([...u, key]));
        } else {
            setSelectedMeets(u => new Set([...u].filter(l => l !== key)));
        }
    };

    return (
        <Grid item>
            <ToggleButton
                key={key}
                value={key}
                sx={{
                    width: 200,
                    margin: "10px",
                    textTransform: "initial",
                    borderColor: invalid ? "red" : "initial"
                }}
                selected={isSelected}
                onChange={toggle}
            >
                <Typography>
                    <Typography sx={{ fontWeight: 500 }}>{shortenedNameA}</Typography> vs.{" "}
                    <Typography sx={{ fontWeight: 500 }}>{shortenedNameB}</Typography>
                </Typography>
            </ToggleButton>
        </Grid>
    );
};

const AddingMeetModal = ({
    meets,
    rounds,
    roundIdx,
    submit
}: {
    meets: Meet[];
    rounds: Round[];
    roundIdx: number;
    submit: (meets: Meet[]) => void;
}) => {
    const theme = useTheme();

    const [selectedMeets, setSelectedMeets] = useState<Set<string>>(new Set());
    const [searchQuery, setSearchQuery] = useState("");
    const [validMeets, setValidMeets] = useState<Meet[]>([]);
    const [validMeetIds, setValidMeetIds] = useState<string[]>([]);
    const [allMeets, setAllMeets] = useState<Meet[]>([]);
    const [allMeetsObj, setAllMeetsObj] = useState<Record<string, Meet>>({});

    useEffect(() => {
        const meetObj: Record<string, Meet> = {};
        for (const meet of meets) {
            meetObj[`${meet.a.id}|${meet.b.id}`] = meet;
        }

        setAllMeetsObj(meetObj);

        const allMeets = Object.values(meetObj).filter(l => {
            const shortenedNameA = l.a.name.replace(/ Men('|’)s/g, "").replace(/ Women('|’)s/g, "");
            const shortenedNameB = l.b.name.replace(/ Men('|’)s/g, "").replace(/ Women('|’)s/g, "");
            return `${shortenedNameA} vs. ${shortenedNameB}`.toLowerCase().includes(searchQuery.toLowerCase());
        });

        setAllMeets(allMeets);

        for (const round of rounds) {
            for (const meet of round.meets) {
                const key = `${meet.a.id}|${meet.b.id}`;
                if (key in meetObj) delete meetObj[key];
            }
        }

        const teamsInRound = rounds[roundIdx].meets.reduce((acc, cur) => [...acc, cur.a.id, cur.b.id], []);
        for (const team of teamsInRound) {
            const keys = Object.keys(meetObj);
            for (let i = 0; i < keys.length; i++) {
                if (keys[i].includes(team)) {
                    delete meetObj[keys[i]];
                    keys.splice(i, 1);
                    i--;
                }
            }
        }

        const validMeets = Object.values(meetObj).filter(l => {
            const shortenedNameA = l.a.name.replace(/ Men('|’)s/g, "").replace(/ Women('|’)s/g, "");
            const shortenedNameB = l.b.name.replace(/ Men('|’)s/g, "").replace(/ Women('|’)s/g, "");
            return `${shortenedNameA} vs. ${shortenedNameB}`.toLowerCase().includes(searchQuery.toLowerCase());
        });

        setValidMeets(validMeets);
        setValidMeetIds(validMeets.map(l => `${l.a.id}|${l.b.id}`));
    }, [meets, searchQuery]);

    const accordionStyle = {
        border: `1px solid ${theme.palette.divider}`,
        "&:not(:last-child)": {
            borderBottom: `0`
        },
        "&::before": {
            display: "none"
        }
    };

    return (
        <Box sx={{ ...modalBoxStyle, maxWidth: "1100px" }}>
            <Typography variant="h4" fontFamily="Lexend Deca" sx={{ marginBottom: "20px" }}>
                Add meet
            </Typography>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <TextField
                    label="Search for a meet"
                    placeholder="Type query here..."
                    variant="outlined"
                    sx={{ margin: "0 auto 20px auto" }}
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                />
            </Box>
            <Box>
                <Accordion elevation={0} sx={accordionStyle} disableGutters defaultExpanded>
                    <AccordionSummary expandIcon={<Icon path={mdiChevronDown} size="20px" />}>
                        <Tooltip title="Available meets that would not cause a conflict with the rest of the schedule.">
                            <Typography>Valid meets</Typography>
                        </Tooltip>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container>
                            {validMeets.map(meet => (
                                <AddingMeetOption
                                    key={`${meet.a.id}|${meet.b.id}`}
                                    meet={meet}
                                    selectedMeets={selectedMeets}
                                    setSelectedMeets={setSelectedMeets}
                                />
                            ))}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion elevation={0} sx={accordionStyle} disableGutters>
                    <AccordionSummary expandIcon={<Icon path={mdiChevronDown} size="20px" />}>
                        <Tooltip title="All meets, even those that would conflict with a different part of the schedule.">
                            <Typography>All meets</Typography>
                        </Tooltip>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container>
                            {allMeets.map(meet => (
                                <AddingMeetOption
                                    key={`${meet.a.id}|${meet.b.id}`}
                                    meet={meet}
                                    invalid={!validMeetIds.includes(`${meet.a.id}|${meet.b.id}`)}
                                    selectedMeets={selectedMeets}
                                    setSelectedMeets={setSelectedMeets}
                                />
                            ))}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Box>
            <Button
                variant="contained"
                sx={{ margin: "10px auto 0 auto" }}
                disabled={!selectedMeets.size}
                onClick={() => submit([...selectedMeets].map(l => allMeetsObj[l]))}
            >
                Add meets ({selectedMeets.size})
            </Button>
        </Box>
    );
};

interface MeetInRoundProps {
    error: boolean;
    gender: "mens" | "womens";
    meet: Meet;
    moveMeetLeft: (gender: "mens" | "womens", meet: Meet) => void;
    moveMeetRight: (gender: "mens" | "womens", meet: Meet) => void;
    moveMeetUp: (gender: "mens" | "womens", meet: Meet) => void;
    moveMeetDown: (gender: "mens" | "womens", meet: Meet) => void;
    switchSides: (gender: "mens" | "womens", meet: Meet) => void;
    deleteMeet: (gender: "mens" | "womens", meet: Meet) => void;
}

const MeetInRound = ({
    error,
    gender,
    meet,
    moveMeetLeft,
    moveMeetRight,
    moveMeetUp,
    moveMeetDown,
    switchSides,
    deleteMeet
}: MeetInRoundProps) => {
    return (
        <Paper
            className="meetInRound"
            style={{ width: 160, borderColor: error ? "red" : undefined }}
            variant="outlined"
            key={`meet${meet.a.id}${meet.b.id}`}
        >
            <IconButton onClick={() => moveMeetLeft(gender, meet)} size="small">
                <Icon path={mdiChevronLeft} size={0.5} horizontal vertical rotate={180} />
            </IconButton>
            <Box
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                <Box
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignSelf: "stretch"
                    }}
                >
                    <IconButton onClick={() => switchSides(gender, meet)} size="small">
                        <Icon path={mdiSwapHorizontal} size={0.5} horizontal vertical rotate={180} />
                    </IconButton>
                    <IconButton onClick={() => moveMeetUp(gender, meet)} size="small">
                        <Icon path={mdiChevronUp} size={0.5} horizontal vertical rotate={180} />
                    </IconButton>
                    <IconButton onClick={() => deleteMeet(gender, meet)} size="small">
                        <Icon path={mdiDelete} size={0.5} horizontal vertical rotate={180} />
                    </IconButton>
                </Box>
                <Box
                    style={{
                        display: "flex",
                        alignItems: "center",
                        width: 115
                    }}
                >
                    <Typography style={{ textAlign: "center", flex: 1 }}>
                        {(meet.a.abbreviation || meet.a.name).replaceAll(/ Men('|’)s/g, "").replaceAll(" Women's", "")}
                    </Typography>
                    <Typography
                        style={{
                            textAlign: "center",
                            width: "20px",
                            color: "#777"
                        }}
                    >
                        vs.
                    </Typography>
                    <Typography style={{ textAlign: "center", flex: 1 }}>
                        {(meet.b.abbreviation || meet.b.name).replaceAll(/ Men('|’)s/g, "").replaceAll(" Women's", "")}
                    </Typography>
                </Box>
                <IconButton onClick={() => moveMeetDown(gender, meet)} size="small">
                    <Icon path={mdiChevronDown} size={0.5} horizontal vertical rotate={180} />
                </IconButton>
            </Box>
            <IconButton onClick={() => moveMeetRight(gender, meet)} size="small">
                <Icon path={mdiChevronRight} size={0.5} horizontal vertical rotate={180} />
            </IconButton>
        </Paper>
    );
};

interface RoundsDisplayProps {
    gender: "mens" | "womens";
    rounds: Round[];
    moveMeetLeft: (gender: "mens" | "womens", meet: Meet) => void;
    moveMeetRight: (gender: "mens" | "womens", meet: Meet) => void;
    moveMeetUp: (gender: "mens" | "womens", meet: Meet) => void;
    moveMeetDown: (gender: "mens" | "womens", meet: Meet) => void;
    switchSides: (gender: "mens" | "womens", meet: Meet) => void;
    deleteMeet: (gender: "mens" | "womens", meet: Meet) => void;
    addRound: (gender: "mens" | "womens") => void;
    deleteRound: (gender: "mens" | "womens", roundIdx: number) => void;
    addMeet: (gender: "mens" | "womens", roundIdx: number) => void;
}

const RoundsDisplay = ({
    gender,
    rounds,
    moveMeetLeft,
    moveMeetRight,
    moveMeetDown,
    moveMeetUp,
    switchSides,
    deleteMeet,
    addRound,
    deleteRound,
    addMeet
}: RoundsDisplayProps) => {
    const byesPresent = rounds.some(l => l.byes.length > 0);
    const podCount = Math.max(...rounds.map(l => l.meets.length));

    const meetErrors = rounds.map(round => {
        const errors: number[] = [];
        for (let i = 0; i < round.meets.length; i++) {
            const meet = round.meets[i];
            const otherMeets1 = round.meets.filter(l => l.a.id === meet.a.id || l.b.id === meet.a.id);
            const otherMeets2 = round.meets.filter(l => l.a.id === meet.b.id || l.b.id === meet.b.id);

            if (otherMeets1.length > 1 || otherMeets2.length > 1) {
                errors.push(i);
            }
        }
        return errors;
    });

    return (
        <Paper
            sx={{
                height: 340,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                overflowX: "auto",
                overflowY: "auto",
                paddingBottom: "10px"
            }}
            elevation={0}
        >
            {rounds.length === 0 ? (
                <Box
                    sx={{
                        height: 340,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <Typography variant="subtitle1" sx={{ color: "#777" }}>
                        Waiting to generate rounds...
                    </Typography>
                </Box>
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        paddingTop: "20px",
                        textAlign: "center",
                        minWidth: 100 + podCount * 170 + +byesPresent * 200
                    }}
                >
                    <Box sx={{ flexShrink: 0, width: 100 }}></Box>
                    {Array(podCount)
                        .fill(null)
                        .map((_, idx) => (
                            <Box sx={{ flexShrink: 0, width: 170, height: 28 }} key={`podLabel${idx}`}>
                                Meet {idx + 1}
                            </Box>
                        ))}
                    <Box sx={{ flexGrow: 1 }}></Box>
                    {byesPresent ? (
                        <Box sx={{ flexShrink: 0, width: 200 }}>
                            <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
                                Teams with Byes
                            </Typography>
                        </Box>
                    ) : (
                        <></>
                    )}
                </Box>
            )}
            {rounds.map((round, idx) => (
                <Box
                    key={`round${idx}`}
                    style={{
                        minWidth: 100 + podCount * 170 + +byesPresent * 200
                    }}
                >
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            flexShrink: 0,
                            flexGrow: 0,
                            alignItems: "center",
                            padding: "10px 0",
                            borderBottom: "1px solid #EEE"
                        }}
                    >
                        <Box
                            sx={{
                                width: 100,
                                flexGrow: 0,
                                flexShrink: 0,
                                textAlign: "center"
                            }}
                        >
                            <Typography variant="subtitle1">{round.name}</Typography>
                            <IconButton onClick={() => deleteRound(gender, idx)}>
                                <Icon path={mdiDelete} size={1} horizontal vertical rotate={180} />
                            </IconButton>
                        </Box>
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: "flex",
                                alignItems: "center"
                            }}
                        >
                            {round.meets.map((meet, meetIdx) => (
                                <MeetInRound
                                    key={`meetInRound${meet.a.id}${meet.b.id}`}
                                    error={meetErrors[idx].includes(meetIdx)}
                                    gender={gender}
                                    meet={meet}
                                    moveMeetLeft={moveMeetLeft}
                                    moveMeetRight={moveMeetRight}
                                    moveMeetDown={moveMeetDown}
                                    moveMeetUp={moveMeetUp}
                                    switchSides={switchSides}
                                    deleteMeet={deleteMeet}
                                />
                            ))}
                            <CreateMeetCard startCreatingMeet={() => addMeet(gender, idx)} />
                        </Box>
                        {byesPresent && (
                            <Box
                                sx={{
                                    width: 200,
                                    flexShrink: 0,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexWrap: "wrap"
                                }}
                            >
                                {round.byes.map(team => (
                                    <Paper
                                        sx={{
                                            padding: "5px 10px",
                                            flexShrink: 0,
                                            margin: "5px"
                                        }}
                                        variant="outlined"
                                        key={`bye${team.id}`}
                                    >
                                        <Typography style={{ textAlign: "center" }}>
                                            {(team.abbreviation || team.name).replace(" Men's", "").replace(" Women's", "")}
                                        </Typography>
                                    </Paper>
                                ))}
                            </Box>
                        )}
                    </Box>
                </Box>
            ))}
            <Button variant="outlined" style={{ margin: "10px auto 0 auto" }} onClick={() => addRound(gender)}>
                Add round
            </Button>
        </Paper>
    );
};

interface CreateRoundsProps {
    activeRoundGender: number;
    setActiveRoundGender: React.Dispatch<React.SetStateAction<number>>;
    generatedMensRounds: Round[];
    generatedWomensRounds: Round[];
    mensStrips: number | null;
    womensStrips: number | null;
    setMensStrips: React.Dispatch<React.SetStateAction<number | null>>;
    setWomensStrips: React.Dispatch<React.SetStateAction<number | null>>;
    moveMeetLeft: (gender: "mens" | "womens", meet: Meet) => void;
    moveMeetRight: (gender: "mens" | "womens", meet: Meet) => void;
    moveMeetDown: (gender: "mens" | "womens", meet: Meet) => void;
    moveMeetUp: (gender: "mens" | "womens", meet: Meet) => void;
    switchSides: (gender: "mens" | "womens", meet: Meet) => void;
    addMeet: (gender: "mens" | "womens", round: number) => void;
    deleteMeet: (gender: "mens" | "womens", meet: Meet) => void;
    duplicateRounds: () => void;
    regenerateRounds: () => void;
    addRound: (gender: "mens" | "womens") => void;
    deleteRound: (gender: "mens" | "womens", roundIdx: number) => void;
}

const CreateRounds = ({
    activeRoundGender,
    setActiveRoundGender,
    generatedMensRounds,
    generatedWomensRounds,
    mensStrips,
    womensStrips,
    setMensStrips,
    setWomensStrips,
    moveMeetLeft,
    moveMeetRight,
    moveMeetDown,
    moveMeetUp,
    switchSides,
    addMeet,
    deleteMeet,
    duplicateRounds,
    regenerateRounds,
    addRound,
    deleteRound
}: CreateRoundsProps) => {
    return (
        <Box className="stepBox">
            <Typography variant="h6" style={{ margin: 10 }}>
                Create Rounds
            </Typography>
            <Tabs value={activeRoundGender} onChange={(e, value) => setActiveRoundGender(value)}>
                <Tab label="Men's" />
                <Tab label="Women's" />
            </Tabs>
            <Paper sx={{ height: 400, width: "100%", marginBottom: "10px" }}>
                <Box
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        height: 40,
                        marginTop: "20px"
                    }}
                >
                    <Button onClick={duplicateRounds} sx={{ margin: "0 20px" }}>
                        Copy {activeRoundGender ? "men" : "women"}'s schedule
                    </Button>
                    <Button onClick={regenerateRounds}>Auto-generate rounds</Button>
                </Box>
                {activeRoundGender === 0 && (
                    <RoundsDisplay
                        gender="mens"
                        moveMeetLeft={moveMeetLeft}
                        moveMeetRight={moveMeetRight}
                        moveMeetDown={moveMeetDown}
                        moveMeetUp={moveMeetUp}
                        rounds={generatedMensRounds}
                        switchSides={switchSides}
                        addMeet={addMeet}
                        deleteMeet={deleteMeet}
                        addRound={addRound}
                        deleteRound={deleteRound}
                    />
                )}
                {activeRoundGender === 1 && (
                    <RoundsDisplay
                        gender="womens"
                        moveMeetLeft={moveMeetLeft}
                        moveMeetRight={moveMeetRight}
                        moveMeetDown={moveMeetDown}
                        moveMeetUp={moveMeetUp}
                        rounds={generatedWomensRounds}
                        switchSides={switchSides}
                        addMeet={addMeet}
                        deleteMeet={deleteMeet}
                        addRound={addRound}
                        deleteRound={deleteRound}
                    />
                )}
            </Paper>
        </Box>
    );
};

type Division = "div1" | "div2" | "div3" | "club";
type ICollegeEventTeamExtended = ICollegeEventTeam & {
    division: Division;
};

const EventCreation = () => {
    const DB = useDatabase();
    const history = useHistory();
    const userInfo = useSelector((s: ReduxState) => s.userInfo);

    const [activeStep, setActiveStep] = useState(EventCreationSteps.ChooseSchools);
    const [orgsLoaded, setOrgsLoaded] = useState(false);
    const [teamsLoaded, setTeamsLoaded] = useState(false);
    const loaded = orgsLoaded && teamsLoaded;

    const [mensUpdateKey, setMensUpdateKey] = useState(0);
    const [womensUpdateKey, setWomensUpdateKey] = useState(0);
    const [customizationUpdateKey, setCustomizationUpdateKey] = useState(0);
    const [organizations, setOrganizations] = useState<OrganizationSettings[]>([]);
    const [teams, setTeams] = useState<Record<string, TeamOption>>({});
    const [editingSchool, setEditingSchool] = useState<OrganizationSettings | null>(null);
    const [mensStrips, setMensStrips] = useState<number | null>(9999);
    const [womensStrips, setWomensStrips] = useState<number | null>(9999);
    const [activeRoundGender, setActiveRoundGender] = useState(0);
    const [meetsChanged, setMeetsChanged] = useState(false);
    const [confirmingCreation, setConfirmingCreation] = useState(false);
    const [eventCreationLoading, setEventCreationLoading] = useState(false);
    const [addingWriteIn, setAddingWriteIn] = useState(false);
    const [addingMeet, setAddingMeet] = useState(false);
    const [addingMeetGender, setAddingMeetGender] = useState<"mens" | "womens">("mens");
    const [addingMeetRound, setAddingMeetRound] = useState(0);

    const [generatedMensMeets, setGeneratedMensMeets] = useState<Meet[]>([]);
    const [generatedWomensMeets, setGeneratedWomensMeets] = useState<Meet[]>([]);

    const [mensRounds, setMensRounds] = useState<Round[]>([{ name: "Round 1", meets: [], byes: [] }]);
    const [womensRounds, setWomensRounds] = useState<Round[]>([{ name: "Round 1", meets: [], byes: [] }]);

    const [mensTeams, setMensTeams] = useState<TeamOption[]>([]);
    const [womensTeams, setWomensTeam] = useState<TeamOption[]>([]);

    const [eventName, setEventName] = useState("");
    const [eventLocation, setEventLocation] = useState("");
    const [eventAddress, setEventAddress] = useState("");
    const [startDate, setStartDate] = useState<Date>(new Date());

    const [unauthorized, setUnauthorized] = useState(false);
    const [criticalError, setCriticalError] = useState("");
    const [createError, setCreateError] = useState("");

    const isAdmin = Boolean((userInfo?.flags || 0) & UserFlag.UberAdmin);

    useEffect(() => {
        if (userInfo && ![...userInfo.teams, ...userInfo.managingTeams].length) {
            setUnauthorized(true);
            return;
        }

        DB.getOrganizationList().then(orgs => {
            if (orgs.status === "fail") return setCriticalError(orgs.data);
            const mapped = Object.values(orgs.data).map(org => ({
                data: org,
                boysTeamActive: false,
                girlsTeamActive: false,
                boysJVTeamActive: false,
                girlsJVTeamActive: false,
                isHostSchool: false
            }));
            mapped.sort((a, b) => a.data.name.localeCompare(b.data.name));
            setOrganizations(mapped);
            setOrgsLoaded(true);
        });
        DB.getTeamList().then(result => {
            if (result.status === "fail") return setCriticalError(result.data);
            const teams = result.data;
            setTeams(teams);
            setTeamsLoaded(true);

            if (userInfo) {
                if (!isAdmin && [...userInfo.teams, ...userInfo.managingTeams].every(team => !teams[team]?.published)) {
                    setUnauthorized(true);
                }
            } else {
                setUnauthorized(true);
            }
        });
    }, []);

    useEffect(() => {
        if (loaded) {
            updateByes("mens");
            updateByes("womens");
        }
    }, [loaded]);

    useEffect(() => {
        const mens: TeamOption[] = [];

        for (const org of organizations) {
            if (org.boysTeamActive && org.data.boysTeam) {
                mens.push(teams[org.data.boysTeam]);
            }
            if (org.boysJVTeamActive && org.data.boysJVTeam) {
                mens.push(teams[org.data.boysJVTeam]);
            }
        }

        setMensTeams(mens);
    }, [mensUpdateKey]);

    useEffect(() => {
        const womens: TeamOption[] = [];

        for (const org of organizations) {
            if (org.girlsTeamActive && org.data.girlsTeam) {
                womens.push(teams[org.data.girlsTeam]);
            }
            if (org.girlsJVTeamActive && org.data.girlsJVTeam) {
                womens.push(teams[org.data.girlsJVTeam]);
            }
        }

        setWomensTeam(womens);
    }, [womensUpdateKey]);

    useEffect(() => {
        updateByes("mens");
    }, [mensTeams]);

    useEffect(() => {
        updateByes("womens");
    }, [womensTeams]);

    useEffect(() => {
        const meets = generateMeets();

        if (meets.mens) setGeneratedMensMeets(meets.mens);
        if (meets.womens) setGeneratedWomensMeets(meets.womens);

        setMeetsChanged(false);
    }, [mensTeams, womensTeams]);

    if (criticalError) {
        return <ErrorPage message={criticalError} />;
    }

    const duplicateRounds = () => {
        const newRounds: Round[] = [];

        const otherRounds = activeRoundGender === 0 ? womensRounds : mensRounds;
        const prefix = activeRoundGender === 0 ? "boysTeam" : "girlsTeam";

        for (let i = 0; i < otherRounds.length; i++) {
            const newRound: Round = {
                name: `Round ${newRounds.length + 1}`,
                byes: [],
                meets: []
            };
            newRounds.push(newRound);
            const otherRound = otherRounds[i];
            for (const meet of otherRound.meets) {
                if (!meet.a.orgID || !meet.b.orgID) continue;
                const orgA = organizations.find(l => l.data.id === meet.a.orgID);
                const orgB = organizations.find(l => l.data.id === meet.b.orgID);

                if (orgA?.data[prefix] && orgB?.data[prefix]) {
                    newRound.meets.push({
                        a: teams[orgA.data[prefix]!],
                        b: teams[orgB.data[prefix]!]
                    });
                }
            }
        }

        updateByes(activeRoundGender === 0 ? "mens" : "womens", newRounds);
        if (activeRoundGender === 0) {
            setMensRounds(newRounds);
        } else {
            setWomensRounds(newRounds);
        }
    };

    const regenerateRounds = () => {
        if (activeRoundGender === 0) {
            if (!mensStrips) return;

            if (generatedMensMeets.length) {
                const rounds = generateRounds(mensTeams, mensStrips);
                setMensRounds(rounds);
            }
        } else {
            if (!womensStrips) return;

            if (generatedWomensMeets.length) {
                const rounds = generateRounds(womensTeams, womensStrips);
                setWomensRounds(rounds);
            }
        }
    };

    const createEvent = async () => {
        setEventCreationLoading(true);

        const meetConverter = (j: Meet) => ({
            id: "",
            nameA: j.a.name,
            nameB: j.b.name,
            idA: j.a.id,
            idB: j.b.id,
            abbA: j.a.abbreviation!,
            abbB: j.b.abbreviation!,
            regionA: j.a.region,
            regionB: j.b.region
        });

        // Filters out any empty rounds
        const filteredMensRounds = mensRounds
            .map(l => ({
                name: l.name,
                meets: l.meets.map(meetConverter),
                byes: l.byes.map(j => ({
                    name: j.name,
                    id: j.id,
                    abb: j.abbreviation!,
                    region: j.region
                }))
            }))
            .filter(l => l.meets.length);

        // Filters out any empty rounds
        const filteredWomensRounds = womensRounds
            .map(l => ({
                name: l.name,
                meets: l.meets.map(meetConverter),
                byes: l.byes.map(j => ({
                    name: j.name,
                    id: j.id,
                    abb: j.abbreviation!,
                    region: j.region
                }))
            }))
            .filter(l => l.meets.length);

        const host = organizations.find(l => l.isHostSchool);

        const mens: ICollegeEventTeamExtended[] = mensTeams.map(team => {
            let type = team.region as CollegeProgramType;
            if ("administrators" in team) {
                if (organizations.some(l => l.data.boysJVTeam === team.id)) {
                    type = CollegeProgramType.JV;
                } else {
                    type = team.region === "club" ? CollegeProgramType.Club : CollegeProgramType.Varsity;
                }
            }

            let division = "div1" as Division;
            const org = organizations.find(l => l.data.id === team.orgID)!;
            if ("division" in org.data) {
                division = org.data.division as Division;
            } else {
                division = org.data.region as Division;
            }

            return {
                id: team.id,
                name: team.name,
                abbreviation: team.abbreviation || genAbbreviationFromName(team.name),
                orgID: team.orgID,
                division,
                type
            };
        });

        const womens: ICollegeEventTeamExtended[] = womensTeams.map(team => {
            let type = team.region as CollegeProgramType;
            if ("administrators" in team) {
                if (organizations.some(l => l.data.girlsJVTeam === team.id)) {
                    type = CollegeProgramType.JV;
                } else {
                    type = team.region === "club" ? CollegeProgramType.Club : CollegeProgramType.Varsity;
                }
            }

            let division = "div1" as Division;
            const org = organizations.find(l => l.data.id === team.orgID)!;
            if ("division" in org.data) {
                division = org.data.division as Division;
            } else {
                division = org.data.region as Division;
            }

            return {
                id: team.id,
                name: team.name,
                abbreviation: team.abbreviation || genAbbreviationFromName(team.name),
                orgID: team.orgID,
                division,
                type
            };
        });

        const writeIns = [...mens, ...womens].filter(l => l.id.startsWith("writeIn"));
        const writeInOrgs = [...new Set(writeIns.map(l => l.orgID))].map(l => writeIns.filter(j => j.orgID === l));
        const newIDs: Record<string, string> = {};

        for (const teams of writeInOrgs) {
            const team = teams[0];
            const boysTeam = teams.find(l => l.name.includes("Men's") && l.type !== "JV");
            const girlsTeam = teams.find(l => l.name.includes("Women's") && l.type !== "JV");
            const boysTeamJV = teams.find(l => l.name.includes("Men's") && l.type === "JV");
            const girlsTeamJV = teams.find(l => l.name.includes("Women's") && l.type === "JV");

            const realName = team.name.replace(/ Men('|’)s/g, "").replace(/ Women('|’)s/g, "");
            const realAbb = team.abbreviation.replace(/ Men('|’)s/g, "").replace(/ Women('|’)s/g, "");

            const newOrgResult = await DB.createOrganization(
                realName,
                "US",
                team.division,
                {
                    boysTeam: boysTeam?.type || CollegeProgramType.None,
                    girlsTeam: girlsTeam?.type || CollegeProgramType.None,
                    boysTeamJV: Boolean(boysTeamJV),
                    girlsTeamJV: Boolean(girlsTeamJV)
                },
                realAbb,
                SchoolType.College,
                true
            );
            if (newOrgResult.status === "fail") return setCreateError(newOrgResult.data);
            const newOrg = newOrgResult.data;
            newIDs[team.orgID!] = newOrg.id;
            if (newOrg.boysTeam && boysTeam?.id) {
                newIDs[boysTeam.id] = newOrg.boysTeam;
                boysTeam.id = newOrg.boysTeam;
            }
            if (newOrg.girlsTeam && girlsTeam?.id) {
                newIDs[girlsTeam.id] = newOrg.girlsTeam;
                girlsTeam.id = newOrg.girlsTeam;
            }
            if (newOrg.boysJVTeam && boysTeamJV?.id) {
                newIDs[boysTeamJV.id] = newOrg.boysJVTeam;
                boysTeamJV.id = newOrg.boysJVTeam;
            }
            if (newOrg.girlsJVTeam && girlsTeamJV?.id) {
                newIDs[girlsTeamJV.id] = newOrg.girlsJVTeam;
                girlsTeamJV.id = newOrg.girlsJVTeam;
            }
            team.orgID = newOrg.id;
        }

        for (const round of filteredMensRounds) {
            for (const meet of round.meets) {
                meet.idA = newIDs[meet.idA] || meet.idA;
                meet.idB = newIDs[meet.idB] || meet.idB;
            }
            for (const bye of round.byes) {
                bye.id = newIDs[bye.id] || bye.id;
            }
        }

        for (const round of filteredWomensRounds) {
            for (const meet of round.meets) {
                meet.idA = newIDs[meet.idA] || meet.idA;
                meet.idB = newIDs[meet.idB] || meet.idB;
            }
            for (const bye of round.byes) {
                bye.id = newIDs[bye.id] || bye.id;
            }
        }

        const res = await DB.createNewCollegeEvent(
            filteredMensRounds,
            filteredWomensRounds,
            mens,
            womens,
            eventName,
            eventLocation,
            eventAddress,
            startDate,
            host?.data?.name || "Unknown host"
        );
        if (res.status === "success") {
            setEventCreationLoading(false);
            history.push(`/eventInfo/${res.data}`);
        }
    };

    const prevStep = () => {
        if (activeStep > EventCreationSteps.ChooseSchools) setActiveStep(u => u - 1);
    };

    const nextStep = () => {
        if (activeStep < EventCreationSteps.EventDetails) {
            setActiveStep(u => u + 1);
        } else {
            setConfirmingCreation(true);
        }
    };

    const generateMeets = (): { mens?: Meet[]; womens?: Meet[] } => {
        const mensMeetOrder = TournamentOrderArrs[mensTeams.length] || genOrderArr(mensTeams.length);
        const res: { mens?: Meet[]; womens?: Meet[] } = {};

        if (mensMeetOrder) {
            const mensMeets: Meet[] = [];
            for (const pair of mensMeetOrder) {
                const a = mensTeams[pair[0] - 1];
                const b = mensTeams[pair[1] - 1];

                if (a.orgID === b.orgID) continue;

                mensMeets.push({ a, b });
            }
            res.mens = mensMeets;
        }

        const womensMeetOrder = TournamentOrderArrs[womensTeams.length] || genOrderArr(womensTeams.length);
        if (womensMeetOrder) {
            const womensMeets: Meet[] = [];
            for (const pair of womensMeetOrder) {
                const a = womensTeams[pair[0] - 1];
                const b = womensTeams[pair[1] - 1];

                if (a.orgID === b.orgID) continue;

                womensMeets.push({ a, b });
            }
            res.womens = womensMeets;
        }

        return res;
    };

    const generateRounds = (teams: TeamOption[], strips: number) => {
        const rounds: Round[] = [];

        const bannedPairings: [number, number][] = [];

        for (let i = 0; i < teams.length; i++) {
            for (let j = 0; j < teams.length; j++) {
                if (teams[i].orgID && teams[j].orgID && teams[i].orgID === teams[j].orgID) bannedPairings.push([i + 1, j + 1]);
            }
        }

        const order = GenTournamentOrder(teams.length, strips, bannedPairings);

        for (let i = 0; i < order.length; i++) {
            const round = order[i];

            const currentRound: Round = {
                name: `Round ${i + 1}`,
                meets: round.map(([a, b]) => ({
                    a: teams[a - 1],
                    b: teams[b - 1]
                })),
                byes: []
            };

            const teamIndices = Object.keys(teams).map(l => Number(l) + 1);
            const byes = teamIndices.filter(l => !round.flat().includes(l));

            currentRound.byes = byes.map(l => teams[l - 1]);

            rounds.push(currentRound);
        }

        return rounds;
    };

    const updateByes = (gender: "mens" | "womens", providedRounds?: Round[]) => {
        const rounds = providedRounds || (gender === "mens" ? mensRounds : womensRounds);
        const relevantTeams = gender === "mens" ? mensTeams : womensTeams;

        for (const round of rounds) {
            const teamsInRound = round.meets.flatMap(l => (l ? [l.a.id, l.b.id] : []));
            const noDuplicates = [...new Set(teamsInRound)];
            const otherTeams = relevantTeams.filter(l => !noDuplicates.includes(l.id));

            round.byes = otherTeams;
        }
    };

    const addRound = (gender: "mens" | "womens") => {
        if (gender === "mens") {
            const newRound: Round = {
                name: `Round ${mensRounds.length + 1}`,
                meets: [],
                byes: [...mensTeams]
            };
            setMensRounds(u => [...u, newRound]);
        } else {
            const newRound: Round = {
                name: `Round ${womensRounds.length + 1}`,
                meets: [],
                byes: [...womensTeams]
            };
            setWomensRounds(u => [...u, newRound]);
        }
    };

    const deleteRound = (gender: "mens" | "womens", roundIdx: number) => {
        (gender === "mens" ? setMensRounds : setWomensRounds)(u => {
            u.splice(roundIdx, 1);
            for (let i = 0; i < u.length; i++) {
                u[i].name = `Round ${i + 1}`;
            }
            return [...u];
        });
    };

    const moveMeetLeft = (gender: "mens" | "womens", meet: Meet) => {
        const rounds = gender === "mens" ? mensRounds : womensRounds;
        const round = rounds.find(l => l.meets.includes(meet));

        if (!round) return;

        const meetIdx = round.meets.indexOf(meet);

        if (meetIdx <= 0) return;

        const leftMeet = round.meets[meetIdx - 1];

        round.meets[meetIdx] = leftMeet;
        round.meets[meetIdx - 1] = meet;

        updateByes(gender);
        setCustomizationUpdateKey(u => u + 1);
    };

    const moveMeetRight = (gender: "mens" | "womens", meet: Meet) => {
        const rounds = gender === "mens" ? mensRounds : womensRounds;
        const round = rounds.find(l => l.meets.includes(meet));

        if (!round) return;

        const meetIdx = round.meets.indexOf(meet);

        if (meetIdx >= round.meets.length - 1) return;

        const rightMeet = round.meets[meetIdx + 1];

        round.meets[meetIdx] = rightMeet;
        round.meets[meetIdx + 1] = meet;

        updateByes(gender);
        setCustomizationUpdateKey(u => u + 1);
    };

    const moveMeetUp = (gender: "mens" | "womens", meet: Meet) => {
        const rounds = gender === "mens" ? mensRounds : womensRounds;
        const roundIdx = rounds.findIndex(l => l.meets.includes(meet));
        const meetIdx = rounds[roundIdx].meets.indexOf(meet);

        if (roundIdx <= 0) return;

        const aboveMeet = rounds[roundIdx - 1].meets[meetIdx];

        rounds[roundIdx].meets[meetIdx] = aboveMeet;
        rounds[roundIdx - 1].meets[meetIdx] = meet;

        rounds[roundIdx].meets = rounds[roundIdx].meets.filter(l => Boolean(l));
        rounds[roundIdx - 1].meets = rounds[roundIdx - 1].meets.filter(l => Boolean(l));

        updateByes(gender);
        setCustomizationUpdateKey(u => u + 1);
    };

    const moveMeetDown = (gender: "mens" | "womens", meet: Meet) => {
        const rounds = gender === "mens" ? mensRounds : womensRounds;
        const roundIdx = rounds.findIndex(l => l.meets.includes(meet));
        const meetIdx = rounds[roundIdx].meets.indexOf(meet);

        if (roundIdx === -1 || roundIdx >= rounds.length - 1) return;

        const belowMeet = rounds[roundIdx + 1].meets[meetIdx];

        rounds[roundIdx].meets[meetIdx] = belowMeet;
        rounds[roundIdx + 1].meets[meetIdx] = meet;

        rounds[roundIdx].meets = rounds[roundIdx].meets.filter(l => Boolean(l));
        rounds[roundIdx + 1].meets = rounds[roundIdx + 1].meets.filter(l => Boolean(l));

        updateByes(gender);
        setCustomizationUpdateKey(u => u + 1);
    };

    const switchSides = (gender: "mens" | "womens", meet: Meet) => {
        const temp = meet.a;
        meet.a = meet.b;
        meet.b = temp;

        setCustomizationUpdateKey(u => u + 1);
    };

    const deleteMeet = (gender: "mens" | "womens", meet: Meet) => {
        const rounds = gender === "mens" ? mensRounds : womensRounds;
        const roundIdx = rounds.findIndex(l => l.meets.includes(meet));
        const meetIdx = rounds[roundIdx].meets.indexOf(meet);

        rounds[roundIdx].meets.splice(meetIdx, 1);

        updateByes(gender);
        setCustomizationUpdateKey(u => u + 1);
    };

    const addWriteIn = (
        name: string,
        abbreviation: string,
        boysTeam: CollegeProgramType,
        girlsTeam: CollegeProgramType,
        boysJVTeam: boolean,
        girlsJVTeam: boolean,
        division: string
    ) => {
        const orgID = `writeInOrg${name}`;
        const boysID = `writeInTeam${name}Mens`;
        const girlsID = `writeInTeam${name}Womens`;
        const boysJVID = `writeInTeam${name}MensJV`;
        const girlSJVID = `writeInTeam${name}WomensJV`;
        setOrganizations(orgs => {
            const newOrgData: WriteInOrganization = {
                id: orgID,
                name,
                abbreviation,
                division,
                boysTeam: boysTeam ? boysID : undefined,
                girlsTeam: girlsTeam ? girlsID : undefined,
                boysJVTeam: boysJVTeam ? boysJVID : undefined,
                girlsJVTeam: girlsJVTeam ? girlSJVID : undefined
            };
            const newOrg: OrganizationSettings = {
                data: newOrgData,
                boysTeamActive: boysTeam !== CollegeProgramType.None,
                girlsTeamActive: girlsTeam !== CollegeProgramType.None,
                boysJVTeamActive: boysJVTeam,
                girlsJVTeamActive: girlsJVTeam,
                isHostSchool: false
            };
            const newOrgs = [...orgs, newOrg];
            return newOrgs;
        });
        setTeams(teams => {
            const newTeams = { ...teams };
            if (boysID) {
                newTeams[boysID] = {
                    name: `${name} Men's`,
                    abbreviation: `${abbreviation} Men's`,
                    region: boysTeam,
                    id: boysID,
                    orgID,
                    division
                } as WriteInTeam;
            }
            if (girlsID) {
                newTeams[girlsID] = {
                    name: `${name} Women's`,
                    abbreviation: `${abbreviation} Women's`,
                    region: girlsTeam,
                    id: girlsID,
                    orgID,
                    division
                } as WriteInTeam;
            }
            if (boysJVID) {
                newTeams[boysJVID] = {
                    name: `${name} Men's - JV`,
                    abbreviation: `${abbreviation} Men's - JV`,
                    region: CollegeProgramType.JV,
                    id: boysJVID,
                    orgID,
                    division
                } as WriteInTeam;
            }
            if (girlSJVID) {
                newTeams[girlSJVID] = {
                    name: `${name} Women's - JV`,
                    abbreviation: `${abbreviation} Women's - JV`,
                    region: CollegeProgramType.JV,
                    id: girlSJVID,
                    orgID,
                    division
                } as WriteInTeam;
            }
            return newTeams;
        });
        setAddingWriteIn(false);
        setMensUpdateKey(u => u + 1);
        setWomensUpdateKey(u => u + 1);
    };

    const EditingSchoolModal = () => {
        const [localUpdateKey, setLocalUpdateKey] = useState(0);

        if (!editingSchool) return <></>;

        const setHost = () => {
            for (const school of organizations) {
                school.isHostSchool = false;
            }
            editingSchool.isHostSchool = true;
            setLocalUpdateKey(u => u + 1);
        };

        const toggleMens = () => {
            editingSchool.boysTeamActive = !editingSchool.boysTeamActive;
            setLocalUpdateKey(u => u + 1);
            setMensUpdateKey(u => u + 1);
            setOrganizations(u => u);
        };

        const toggleWomens = () => {
            editingSchool.girlsTeamActive = !editingSchool.girlsTeamActive;
            setLocalUpdateKey(u => u + 1);
            setWomensUpdateKey(u => u + 1);
            setOrganizations(u => u);
        };

        const toggleJVMens = () => {
            editingSchool.boysJVTeamActive = !editingSchool.boysJVTeamActive;
            setLocalUpdateKey(u => u + 1);
            setMensUpdateKey(u => u + 1);
            setOrganizations(u => u);
        };

        const toggleJVWomens = () => {
            editingSchool.girlsJVTeamActive = !editingSchool.girlsJVTeamActive;
            setLocalUpdateKey(u => u + 1);
            setWomensUpdateKey(u => u + 1);
            setOrganizations(u => u);
        };

        const getTeam = (team: "boysTeam" | "girlsTeam" | "boysJVTeam" | "girlsJVTeam"): TeamOption | null => {
            if (editingSchool.data[team] === undefined) return null;
            if (typeof editingSchool.data[team] === "string") {
                return teams[editingSchool.data[team] as string];
            }
            return null;
        };

        const mensTeam = getTeam("boysTeam");
        const womensTeam = getTeam("girlsTeam");
        const mensJVTeam = getTeam("boysJVTeam");
        const womensJVTeam = getTeam("girlsJVTeam");

        return (
            <Box sx={modalBoxStyle}>
                <Typography variant="h4" fontFamily="Lexend Deca">
                    Choose participating teams
                </Typography>

                <List>
                    {mensTeam && (
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => toggleMens()} dense>
                                <ListItemIcon style={{ marginRight: -20 }}>
                                    <Checkbox
                                        key={`aim${localUpdateKey}`}
                                        edge="start"
                                        checked={editingSchool.boysTeamActive}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            "aria-labelledby": `checkbox-mens`
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={`checkbox-mens`} primary={mensTeam.name} />
                            </ListItemButton>
                        </ListItem>
                    )}
                    {womensTeam && (
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => toggleWomens()} dense>
                                <ListItemIcon style={{ marginRight: -20 }}>
                                    <Checkbox
                                        key={`mimcxim${localUpdateKey}`}
                                        edge="start"
                                        checked={editingSchool.girlsTeamActive}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            "aria-labelledby": `checkbox-womens`
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={`checkbox-womens`} primary={womensTeam.name} />
                            </ListItemButton>
                        </ListItem>
                    )}
                    {mensJVTeam && (
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => toggleJVMens()} dense>
                                <ListItemIcon style={{ marginRight: -20 }}>
                                    <Checkbox
                                        key={`mimcxikim${localUpdateKey}`}
                                        edge="start"
                                        checked={editingSchool.boysJVTeamActive}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            "aria-labelledby": `checkbox-mens-jv`
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={`checkbox-mens-jv`} primary={mensJVTeam.name} />
                            </ListItemButton>
                        </ListItem>
                    )}
                    {womensJVTeam && (
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => toggleJVWomens()} dense>
                                <ListItemIcon style={{ marginRight: -20 }}>
                                    <Checkbox
                                        key={`mimcxmaim${localUpdateKey}`}
                                        edge="start"
                                        checked={editingSchool.girlsJVTeamActive}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            "aria-labelledby": `checkbox-womens-jv`
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={`checkbox-womens-jv`} primary={womensJVTeam.name} />
                            </ListItemButton>
                        </ListItem>
                    )}
                </List>

                <Box style={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="h4" id="checkbox-host-school" sx={{ fontSize: 18, margin: "0 10px 10px 0" }}>
                        {editingSchool.isHostSchool ? "This school is the host school." : "This school is not the host school."}
                    </Typography>
                    {!editingSchool.isHostSchool ? (
                        <Button variant="outlined" onClick={setHost}>
                            Set as host
                        </Button>
                    ) : null}
                </Box>

                <Button variant="contained" color="success" onClick={() => setEditingSchool(null)}>
                    Save
                </Button>
            </Box>
        );
    };

    const ConfirmingCreationModal = () => {
        return (
            <Box sx={modalBoxStyleNarrower}>
                <Typography variant="h4" fontFamily="Lexend Deca">
                    Confirm event creation
                </Typography>

                <Typography variant="body1" sx={{ marginTop: "15px", marginBottom: "10px" }}>
                    Are you sure you want to create the event {eventName}? The event will feature {mensTeams.length} men's teams and{" "}
                    {womensTeams.length} women's teams.
                </Typography>

                <Box
                    style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "end"
                    }}
                >
                    <Button variant="outlined" sx={{ marginRight: "10px" }} onClick={() => setConfirmingCreation(false)}>
                        Edit
                    </Button>
                    <Button variant="contained" onClick={() => createEvent()} disabled={eventCreationLoading}>
                        {eventCreationLoading ? "Creating..." : "Create"}
                    </Button>
                </Box>
            </Box>
        );
    };

    const startAddingMeet = (gender: "mens" | "womens", round: number) => {
        setAddingMeetGender(gender);
        setAddingMeetRound(round);
        setAddingMeet(true);
    };

    const addMeets = (meets: Meet[]) => {
        if (addingMeetGender === "mens") {
            mensRounds[addingMeetRound].meets.push(...meets);
            updateByes("mens");
        } else {
            womensRounds[addingMeetRound].meets.push(...meets);
            updateByes("womens");
        }
        setAddingMeet(false);
    };

    const canAdvance =
        (activeStep === EventCreationSteps.ChooseSchools &&
            (mensTeams.length > 0 || womensTeams.length > 0) &&
            organizations.some(l => l.isHostSchool)) ||
        (activeStep === EventCreationSteps.CreateRounds && (mensRounds.length > 0 || womensRounds.length > 0)) ||
        (activeStep === EventCreationSteps.EventDetails && eventName.trim() && eventAddress.trim() && eventLocation.trim());

    const tooltipMessage =
        (activeStep === EventCreationSteps.ChooseSchools && mensTeams.length <= 0 && womensTeams.length <= 0 && "No schools selected") ||
        (activeStep === EventCreationSteps.ChooseSchools && !organizations.some(l => l.isHostSchool) && "No host school selected") ||
        (activeStep === EventCreationSteps.CreateRounds &&
            mensRounds.length <= 0 &&
            womensRounds.length <= 0 &&
            "No round order generated") ||
        (activeStep === EventCreationSteps.EventDetails && !eventName.trim() && "No event name provided") ||
        (activeStep === EventCreationSteps.EventDetails && !eventAddress.trim() && "No event address provided") ||
        (activeStep === EventCreationSteps.EventDetails && !eventLocation.trim() && "No event location provided") ||
        "Continue";

    if (!userInfo) {
        return <PermissionDenied message="You are not logged in!" />;
    }

    if (unauthorized) {
        return (
            <TbTPage className="eventCreationPage">
                <Card
                    sx={{
                        width: "90%",
                        maxWidth: 600,
                        margin: "10vh auto 20px auto"
                    }}
                >
                    <CardHeader
                        title="Create New Event"
                        titleTypographyProps={{
                            variant: "h4",
                            textAlign: "center",
                            fontFamily: "Lexend Deca"
                        }}
                    />
                    <CardContent sx={{ paddingBottom: "8px !important" }}>
                        In order to create an event, you must first have a published team. Please request administrator publication of your
                        team in your school's page. If you do not currently have any teams, you can create teams for your school in the
                        appropriate page.
                    </CardContent>
                    <Box
                        sx={{
                            width: "100%",
                            height: 60,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <Button href="/createSchool" variant="contained" color="primary">
                            Create School
                        </Button>
                    </Box>
                </Card>
            </TbTPage>
        );
    }

    return (
        <TbTPage className="eventCreationPage">
            <Card
                sx={{
                    width: "90%",
                    maxWidth: 1000,
                    margin: "10vh auto 20px auto"
                }}
            >
                <CardHeader
                    title="Create New Event"
                    titleTypographyProps={{
                        variant: "h4",
                        textAlign: "center",
                        fontFamily: "Lexend Deca"
                    }}
                />
                <CardContent sx={{ paddingBottom: "8px !important" }}>
                    <Stepper activeStep={activeStep}>
                        <Step>
                            <StepLabel>Choose Schools</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>Create Rounds</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>Event Details</StepLabel>
                        </Step>
                    </Stepper>
                    {!loaded && (
                        <Box style={{ height: 500 }}>
                            <CommonLoading />
                        </Box>
                    )}
                    {loaded && activeStep === EventCreationSteps.ChooseSchools && (
                        <ChooseSchools
                            organizations={organizations}
                            mensTeams={mensTeams}
                            womensTeams={womensTeams}
                            setMensUpdateKey={setMensUpdateKey}
                            setWomensUpdateKey={setWomensUpdateKey}
                            setEditingSchool={setEditingSchool}
                            setAddingWriteIn={setAddingWriteIn}
                        />
                    )}
                    {loaded && activeStep === EventCreationSteps.CreateRounds && (
                        <CreateRounds
                            activeRoundGender={activeRoundGender}
                            setActiveRoundGender={setActiveRoundGender}
                            generatedMensRounds={mensRounds}
                            generatedWomensRounds={womensRounds}
                            mensStrips={mensStrips}
                            womensStrips={womensStrips}
                            setMensStrips={setMensStrips}
                            setWomensStrips={setWomensStrips}
                            moveMeetLeft={moveMeetLeft}
                            moveMeetRight={moveMeetRight}
                            moveMeetUp={moveMeetUp}
                            moveMeetDown={moveMeetDown}
                            switchSides={switchSides}
                            addMeet={startAddingMeet}
                            deleteMeet={deleteMeet}
                            duplicateRounds={duplicateRounds}
                            regenerateRounds={regenerateRounds}
                            addRound={addRound}
                            deleteRound={deleteRound}
                        />
                    )}
                    {loaded && activeStep === EventCreationSteps.EventDetails && (
                        <EventDetails
                            eventName={eventName}
                            setEventName={setEventName}
                            eventAddress={eventAddress}
                            setEventAddress={setEventAddress}
                            eventLocation={eventLocation}
                            setEventLocation={setEventLocation}
                            startDate={startDate}
                            setStartDate={setStartDate}
                        />
                    )}
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between"
                        }}
                    >
                        <Button onClick={prevStep} disabled={activeStep === EventCreationSteps.ChooseSchools}>
                            Back
                        </Button>
                        <Tooltip title={tooltipMessage} placement="top">
                            <span>
                                <Button onClick={nextStep} disabled={!canAdvance}>
                                    {activeStep === EventCreationSteps.EventDetails ? "Create" : "Next"}
                                </Button>
                            </span>
                        </Tooltip>
                    </Box>
                </CardContent>
            </Card>
            <Modal open={Boolean(editingSchool)} onClose={() => setEditingSchool(null)}>
                <EditingSchoolModal />
            </Modal>
            <Modal open={confirmingCreation} onClose={() => setConfirmingCreation(false)}>
                <ConfirmingCreationModal />
            </Modal>
            <Modal open={addingWriteIn} onClose={() => setAddingWriteIn(false)}>
                <AddingWriteInModal setAddingWriteIn={setAddingWriteIn} addWriteIn={addWriteIn} />
            </Modal>
            <Modal open={addingMeet} onClose={() => setAddingMeet(false)}>
                <AddingMeetModal
                    meets={addingMeetGender === "mens" ? generatedMensMeets : generatedWomensMeets}
                    rounds={addingMeetGender === "mens" ? mensRounds : womensRounds}
                    roundIdx={addingMeetRound}
                    submit={addMeets}
                />
            </Modal>
        </TbTPage>
    );
};

export default EventCreation;
