import { memo, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import EquipmentOnGround from "../../../assets/EquipmentOnGround.jpg";
import PermissionDenied from "../../../components/PermissionDenied/PermissionDenied";
import TbTPage from "../../../components/TbTPage/TbTPage";
import { DualMeetType, IDualMeetTeam, IOrganization, ITeam, UserFlag, Weapon } from "../../../types";
import { ReduxState } from "../../../utils/store";
import "./DualMeetCreation.css";
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Stack,
    Switch,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { modalBoxStyle } from "../../..";
import { mdiDelete } from "@mdi/js";
import Icon from "@mdi/react";
import useDatabase from "../../../hooks/database";
import WriteInTeamBox from "../../../components/WriteInTeamBox";
import { CommonLoading } from "../../../components/Loading/Loading";
import { DBResult, isSuccess } from "../../../utils/database";
import ErrorPage from "../NotFound/NotFound";

const DualMeetCreation = () => {
    // TODO: possible refactors here with changing variable names
    const [dualMeetName, setDualMeetName] = useState("");
    const [usingCustomName, setUsingCustomName] = useState(false);
    const [meetType, setMeetType] = useState(DualMeetType.Varsity);
    const [team1, setTeam1] = useState<string | null>(null);
    const [team2, setTeam2] = useState<string | null>(null);
    const [writeIn1, setWriteIn1] = useState(false);
    const [writeIn2, setWriteIn2] = useState(false);
    const [writeInOpen1, setWriteInOpen1] = useState(false);
    const [writeInOpen2, setWriteInOpen2] = useState(false);
    const [editingRosterSide, setEditingRosterSide] = useState("mens");
    const [writeInRoster1] = useState<IDualMeetTeam["fencers"]>({
        Sabre: [],
        Foil: [],
        Epee: []
    });
    const [writeInRoster2] = useState<IDualMeetTeam["fencers"]>({
        Sabre: [],
        Foil: [],
        Epee: []
    });
    const [writeInTeamName1, setWriteInTeamName1] = useState("");
    const [writeInTeamName2, setWriteInTeamName2] = useState("");
    const [writeInTeamAbb1, setWriteInTeamAbb1] = useState("");
    const [writeInTeamAbb2, setWriteInTeamAbb2] = useState("");
    const [possibleTeams, setPossibleTeams] = useState<ITeam[]>([]);
    const [possibleOrgs, setPossibleOrgs] = useState<IOrganization[]>([]);
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [selectingOrganizations, setSelectingOrganizations] = useState(true);

    const [creatingMeet, setCreatingMeet] = useState(false);

    const [teamsLoading, setTeamsLoading] = useState(true);
    const [orgsLoading, setOrgsLoading] = useState(true);
    const loading = teamsLoading || orgsLoading;

    const [unauthorized, setUnauthorized] = useState(false);
    const [criticalError, setCriticalError] = useState("");

    const writeInMensRoster1 = useRef({ Sabre: [], Foil: [], Epee: [] });
    const writeInMensRoster2 = useRef({ Sabre: [], Foil: [], Epee: [] });
    const writeInWomensRoster1 = useRef({ Sabre: [], Foil: [], Epee: [] });
    const writeInWomensRoster2 = useRef({ Sabre: [], Foil: [], Epee: [] });

    const history = useHistory();
    const DB = useDatabase();

    const userInfo = useSelector((s: ReduxState) => s.userInfo);

    useEffect(() => {
        const teamsListener = (teams: DBResult<Record<string, ITeam>>) => {
            if (teams.status === "fail") return setCriticalError(teams.data);
            setPossibleTeams(
                Object.values(teams.data)
                    .filter(l => (userInfo?.flags || 0) & UserFlag.UberAdmin || l.published)
                    .sort((a, b) => a.name.localeCompare(b.name))
            );
            setTeamsLoading(false);
            if (userInfo) {
                if ([...userInfo.teams, ...userInfo.managingTeams].every(team => !teams[team].published)) {
                    setUnauthorized(true);
                }
            } else {
                setUnauthorized(true);
            }
        };

        const orgsListener = (orgs: DBResult<Record<string, IOrganization>>) => {
            if (orgs.status === "fail") return setCriticalError(orgs.data);
            setPossibleOrgs(
                Object.values(orgs.data)
                    .filter(l => (userInfo?.flags || 0) & UserFlag.UberAdmin || l.published)
                    .sort((a, b) => a.name.localeCompare(b.name))
            );
            setOrgsLoading(false);
        };

        DB.getTeamList(teamsListener);
        DB.getOrganizationList(orgsListener);

        return () => {
            DB.stopListeningTeamList(teamsListener);
            DB.stopListeningOrganizationList(orgsListener);
        };
    }, []);

    useEffect(() => {
        if (!team1 || !team2 || usingCustomName) return;

        // TS is dumb and I'm too lazy to make this type safe so enjoy
        // @ts-ignore
        const team1Data: ITeam | IOrganization = availableOptions.find((l: ITeam | IOrganization) => l.id === team1)!;
        // @ts-ignore
        const team2Data: ITeam | IOrganization = availableOptions.find((l: ITeam | IOrganization) => l.id === team2)!;

        setDualMeetName(`${team1Data.name} (A) vs. ${team2Data.name} (B)`);
    }, [team1, team2, usingCustomName]);

    useEffect(() => {
        setTeam1(null);
        setTeam2(null);
    }, [selectingOrganizations]);

    if (!userInfo) {
        return <PermissionDenied message="You are not logged in!" />;
    }

    if (loading) {
        return (
            <TbTPage className="dualMeetCreationPage">
                <Card
                    sx={{
                        width: "90%",
                        maxWidth: 1000,
                        margin: "10vh auto 0 auto"
                    }}
                >
                    <CardHeader
                        title="Create New Dual Meet"
                        titleTypographyProps={{
                            variant: "h4",
                            textAlign: "center",
                            fontFamily: "Lexend Deca"
                        }}
                    />
                    <CardContent
                        sx={{
                            display: "flex",
                            padding: "0 !important",
                            height: 440,
                            overflowX: "auto"
                        }}
                    >
                        <CommonLoading />
                    </CardContent>
                </Card>
            </TbTPage>
        );
    }

    if (unauthorized) {
        return (
            <TbTPage className="dualMeetCreationPage">
                <Card
                    sx={{
                        width: "90%",
                        maxWidth: 600,
                        margin: "10vh auto 0 auto"
                    }}
                >
                    <CardHeader
                        title="Create New Dual Meet"
                        titleTypographyProps={{
                            variant: "h4",
                            textAlign: "center",
                            fontFamily: "Lexend Deca"
                        }}
                    />
                    <CardContent sx={{ height: 240, padding: "20px" }}>
                        <Typography variant="h5" fontFamily="Lexend Deca" sx={{ textAlign: "center", marginBottom: "10px" }}>
                            Unauthorized User
                        </Typography>
                        <Typography variant="body1">
                            In order to create a dual meet, you must first have a published team. Please request administrator publication
                            of your team in your school's page. If you do not currently have any teams, you can create teams for your school
                            in the appropriate page.
                        </Typography>
                        <Box
                            sx={{
                                width: "100%",
                                height: 60,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <Button href="/createSchool" variant="contained" color="secondary">
                                Create School
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </TbTPage>
        );
    }

    if (criticalError) {
        return <ErrorPage message={criticalError} />;
    }

    const team1Valid = writeIn1 ? writeInTeamName1 && writeInTeamAbb1 : team1;
    const team2Valid = writeIn2 ? writeInTeamName2 && writeInTeamAbb2 : team2;
    const valid = team1Valid && team2Valid && team1 !== team2;

    const updateTeamName1 = (name: string) => {
        setWriteInTeamName1(name);
        for (const weapon in writeInRoster1) {
            for (const fencer of writeInRoster1[weapon as Weapon]) {
                fencer.teamName = name.trim();
            }
        }
    };

    const updateTeamName2 = (name: string) => {
        setWriteInTeamName2(name);
        for (const weapon in writeInRoster2) {
            for (const fencer of writeInRoster2[weapon as Weapon]) {
                fencer.teamName = name.trim();
            }
        }
    };

    const updateTeamAbbreviation1 = (name: string) => {
        setWriteInTeamAbb1(name);
        for (const weapon in writeInRoster1) {
            for (const fencer of writeInRoster1[weapon as Weapon]) {
                fencer.teamAbbreviation = name.trim();
            }
        }
    };

    const updateTeamAbbreviation2 = (name: string) => {
        setWriteInTeamAbb2(name);
        for (const weapon in writeInRoster2) {
            for (const fencer of writeInRoster2[weapon as Weapon]) {
                fencer.teamAbbreviation = name.trim();
            }
        }
    };

    const submitForm = async () => {
        if (!valid || !startDate || creatingMeet) return;

        const seasonBase = new Date().getMonth() >= 7 ? new Date().getFullYear() : new Date().getFullYear() - 1;
        const season = `${seasonBase}-${seasonBase + 1}`;

        if (selectingOrganizations) {
            const org1T1 = writeIn1
                ? {
                      name: `${writeInTeamName1} Men's`,
                      abbreviation: `${writeInTeamAbb1} Men's`,
                      fencers: writeInMensRoster1.current
                  }
                : possibleOrgs.find(l => l.id === team1)!.boysTeam;
            const org1T2 = writeIn1
                ? {
                      name: `${writeInTeamName1} Women's`,
                      abbreviation: `${writeInTeamAbb1} Women's`,
                      fencers: writeInWomensRoster1.current
                  }
                : possibleOrgs.find(l => l.id === team1)!.girlsTeam;
            const org2T1 = writeIn2
                ? {
                      name: `${writeInTeamName2} Men's`,
                      abbreviation: `${writeInTeamAbb2} Men's`,
                      fencers: writeInMensRoster2.current
                  }
                : possibleOrgs.find(l => l.id === team2)!.boysTeam;
            const org2T2 = writeIn2
                ? {
                      name: `${writeInTeamName2} Women's`,
                      abbreviation: `${writeInTeamAbb2} Women's`,
                      fencers: writeInWomensRoster2.current
                  }
                : possibleOrgs.find(l => l.id === team2)!.girlsTeam;
            let dualMeetId = "";
            if (org1T1 && org2T1) {
                const dbResponse = await DB.createNewDualMeet(
                    org1T1,
                    org2T1,
                    meetType,
                    season,
                    startDate,
                    usingCustomName ? dualMeetName : undefined
                );
                if (isSuccess(dbResponse)) {
                    dualMeetId = dbResponse.data;
                } else {
                    return setCriticalError(dbResponse.data);
                }
            }
            if (org1T2 && org2T2) {
                const dbResponse = await DB.createNewDualMeet(
                    org1T2,
                    org2T2,
                    meetType,
                    season,
                    startDate,
                    usingCustomName ? dualMeetName : undefined,
                    dualMeetId
                );
                if (isSuccess(dbResponse)) {
                    dualMeetId = dbResponse.data;
                } else {
                    return setCriticalError(dbResponse.data);
                }
            }
            history.push(`/meet/${dualMeetId}`);
        } else {
            const submittedTeam1 = writeIn1
                ? {
                      name: writeInTeamName1,
                      abbreviation: writeInTeamAbb1,
                      fencers: writeInRoster1
                  }
                : team1;
            const submittedTeam2 = writeIn2
                ? {
                      name: writeInTeamName2,
                      abbreviation: writeInTeamAbb2,
                      fencers: writeInRoster2
                  }
                : team2;
            const dualMeetId = await DB.createNewDualMeet(
                submittedTeam1!,
                submittedTeam2!,
                meetType,
                season,
                startDate,
                usingCustomName ? dualMeetName : undefined
            );
            history.push(`/meet/${dualMeetId}`);
        }
    };

    const availableOptions = selectingOrganizations ? possibleOrgs : possibleTeams;

    const meetManager = (userInfo.flags || 0) & UserFlag.MeetManager;

    return (
        <TbTPage className="dualMeetCreationPage">
            <Card
                sx={{
                    width: "90%",
                    maxWidth: 1000,
                    margin: "10vh auto 0 auto"
                }}
            >
                <CardHeader
                    title="Create New Dual Meet"
                    titleTypographyProps={{
                        variant: "h4",
                        textAlign: "center",
                        fontFamily: "Lexend Deca"
                    }}
                />
                <CardContent
                    sx={{
                        display: "flex",
                        padding: "0 !important",
                        height: 440,
                        overflowX: "auto"
                    }}
                >
                    <Box
                        sx={{
                            minWidth: 300,
                            flexShrink: 0.5,
                            padding: "10px 15px"
                        }}
                    >
                        <FormControl style={{ marginTop: 10, marginBottom: 10 }} fullWidth>
                            <TextField
                                label="Dual Meet Name (autogenerated)"
                                placeholder="Custom Dual Meet Name"
                                size="small"
                                value={dualMeetName}
                                onChange={e => setDualMeetName(e.target.value)}
                                disabled={!usingCustomName}
                                sx={{
                                    cursor: usingCustomName ? "inherit" : "not-allowed !important"
                                }}
                                fullWidth
                            />
                        </FormControl>
                        {meetManager ? (
                            <FormControlLabel
                                sx={{ margin: "0" }}
                                control={
                                    <Checkbox sx={{ padding: "5px !important" }} onChange={() => setUsingCustomName(!usingCustomName)} />
                                }
                                labelPlacement="start"
                                label="Custom dual meet name?"
                            />
                        ) : (
                            <></>
                        )}
                        <Stack direction="row" spacing={0} alignItems="center" justifyContent="center" sx={{ marginBottom: "15px" }}>
                            <Typography>Teams</Typography>
                            <Switch defaultChecked onChange={() => setSelectingOrganizations(!selectingOrganizations)} />
                            <Typography>Schools</Typography>
                        </Stack>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "10px",
                                height: 43
                            }}
                        >
                            <Stack
                                direction="row"
                                spacing={0}
                                alignItems="center"
                                justifyContent="center"
                                sx={{ marginTop: "5px", flexShrink: 0 }}
                            >
                                <Typography>Write-in</Typography>
                                <Switch onChange={() => setWriteIn1(!writeIn1)} />
                            </Stack>
                            {!writeIn1 ? (
                                <FormControl fullWidth size="small">
                                    <InputLabel id="team1-select">Home {selectingOrganizations ? "School" : "Team"}</InputLabel>
                                    <Select
                                        labelId="team1-select"
                                        id="team1-select"
                                        label={`Home ${selectingOrganizations ? "School" : "Team"}`}
                                        value={team1}
                                        onChange={j => setTeam1(j.target.value)}
                                    >
                                        {availableOptions.map(l => (
                                            <MenuItem value={l.id} key={`dualMeetCreationTeam1${l.id}`}>
                                                {l.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            ) : (
                                <Button variant="contained" sx={{ width: "100%" }} onClick={() => setWriteInOpen1(true)}>
                                    Edit write-in roster
                                </Button>
                            )}
                        </div>
                        {team1 && team1 === team2 && (
                            <Alert severity="warning" sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
                                You cannot select the same school for both sides.
                            </Alert>
                        )}
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "10px",
                                height: 43
                            }}
                        >
                            <Stack
                                direction="row"
                                spacing={0}
                                alignItems="center"
                                justifyContent="center"
                                sx={{ marginTop: "5px", flexShrink: 0 }}
                            >
                                <Typography>Write-in</Typography>
                                <Switch onChange={() => setWriteIn2(!writeIn2)} />
                            </Stack>
                            {!writeIn2 ? (
                                <FormControl fullWidth size="small">
                                    <InputLabel id="team2-select">Away {selectingOrganizations ? "School" : "Team"}</InputLabel>
                                    <Select
                                        labelId="team2-select"
                                        id="team2-select"
                                        label={`Away ${selectingOrganizations ? "School" : "Team"}`}
                                        value={team2}
                                        onChange={j => setTeam2(j.target.value)}
                                    >
                                        {availableOptions.map(l => (
                                            <MenuItem value={l.id} key={`dualMeetCreationTeam2${l.id}`}>
                                                {l.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            ) : (
                                <Button variant="contained" sx={{ width: "100%" }} onClick={() => setWriteInOpen2(true)}>
                                    Edit write-in roster
                                </Button>
                            )}
                        </div>
                        <FormControl fullWidth size="small">
                            <InputLabel id="school-type">Meet Type</InputLabel>
                            <Select
                                labelId="school-type"
                                id="school-type"
                                label="Meet Type"
                                value={meetType}
                                onChange={e => setMeetType(e.target.value as DualMeetType)}
                            >
                                <MenuItem value={DualMeetType.Varsity}>Varsity</MenuItem>
                                <MenuItem value={DualMeetType.Club}>Club</MenuItem>
                                <MenuItem value={DualMeetType.JV}>JV</MenuItem>
                            </Select>
                        </FormControl>
                        <div
                            style={{
                                margin: "10px auto 20px auto",
                                textAlign: "center"
                            }}
                        >
                            <DateTimePicker
                                renderInput={props => <TextField {...props} />}
                                desktopModeMediaQuery="@media only screen and (min-width: 600px)"
                                label="Meet Start Date"
                                value={startDate}
                                onChange={setStartDate}
                            />
                        </div>
                        <Button
                            fullWidth
                            variant="contained"
                            disabled={!valid}
                            className={`${!valid ? "disabledButton" : ""}`}
                            onClick={submitForm}
                        >
                            Create
                        </Button>
                    </Box>
                    <CardMedia component="img" image={EquipmentOnGround} alt="Old fencers" />
                </CardContent>
            </Card>
            <Modal open={writeInOpen1} onClose={() => setWriteInOpen1(false)}>
                <Box sx={modalBoxStyle}>
                    <Typography variant="h4" fontFamily="Lexend Deca" sx={{ marginBottom: "10px" }}>
                        Write-in Team
                    </Typography>
                    <div
                        style={{
                            display: "flex",
                            margin: "10px 0",
                            alignItems: "center",
                            flexWrap: "wrap"
                        }}
                    >
                        <Typography variant="h5" fontFamily="Lexend Deca" sx={{ flexShrink: 0, marginRight: "10px" }}>
                            Team Name
                        </Typography>
                        <TextField
                            label="Write-in team name"
                            placeholder="Write-in team name (e.g. Drew University)"
                            size="small"
                            value={writeInTeamName1}
                            onChange={e => updateTeamName1(e.target.value)}
                            fullWidth
                            sx={{ flexBasis: "400px" }}
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            marginBottom: "10px",
                            alignItems: "center",
                            flexWrap: "wrap"
                        }}
                    >
                        <Typography variant="h5" fontFamily="Lexend Deca" sx={{ flexShrink: 0, marginRight: "10px" }}>
                            Team Abbreviation
                        </Typography>
                        <TextField
                            label="Write-in team abbreviation"
                            placeholder="Write-in team abbreviation (e.g. DU)"
                            size="small"
                            value={writeInTeamAbb1}
                            onChange={e => updateTeamAbbreviation1(e.target.value)}
                            fullWidth
                            sx={{ flexBasis: "400px" }}
                        />
                    </div>
                    {selectingOrganizations ? (
                        <>
                            <Tabs value={editingRosterSide} onChange={(_, v) => setEditingRosterSide(v)} centered>
                                <Tab label="Men's" value="mens" />
                                <Tab label="Women's" value="womens" />
                            </Tabs>
                            <Box hidden={editingRosterSide === "womens"}>
                                <WriteInTeamBox
                                    onClose={() => setWriteInOpen1(false)}
                                    home={true}
                                    roster={writeInMensRoster1.current}
                                    teamName={writeInTeamName1}
                                />
                            </Box>
                            <Box hidden={editingRosterSide === "mens"}>
                                <WriteInTeamBox
                                    onClose={() => setWriteInOpen1(false)}
                                    home={true}
                                    roster={writeInWomensRoster1.current}
                                    teamName={writeInTeamName1}
                                />
                            </Box>
                        </>
                    ) : (
                        <WriteInTeamBox
                            onClose={() => setWriteInOpen1(false)}
                            home={true}
                            roster={writeInRoster1}
                            teamName={writeInTeamName1}
                        />
                    )}
                </Box>
            </Modal>
            <Modal open={writeInOpen2} onClose={() => setWriteInOpen2(false)}>
                <Box sx={modalBoxStyle}>
                    <Typography variant="h4" fontFamily="Lexend Deca" sx={{ marginBottom: "10px" }}>
                        Write-in Team
                    </Typography>
                    <div
                        style={{
                            display: "flex",
                            margin: "10px 0",
                            alignItems: "center",
                            flexWrap: "wrap"
                        }}
                    >
                        <Typography variant="h5" fontFamily="Lexend Deca" sx={{ flexShrink: 0, marginRight: "10px" }}>
                            Team Name
                        </Typography>
                        <TextField
                            label="Write-in team name"
                            placeholder="Write-in team name (e.g. Drew University)"
                            size="small"
                            value={writeInTeamName2}
                            onChange={e => updateTeamName2(e.target.value)}
                            fullWidth
                            sx={{ flexBasis: "400px" }}
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            marginBottom: "10px",
                            alignItems: "center",
                            flexWrap: "wrap"
                        }}
                    >
                        <Typography variant="h5" fontFamily="Lexend Deca" sx={{ flexShrink: 0, marginRight: "10px" }}>
                            Team Abbreviation
                        </Typography>
                        <TextField
                            label="Write-in team abbreviation"
                            placeholder="Write-in team abbreviation (e.g. DU)"
                            size="small"
                            value={writeInTeamAbb2}
                            onChange={e => updateTeamAbbreviation2(e.target.value)}
                            fullWidth
                            sx={{ flexBasis: "400px" }}
                        />
                    </div>
                    {selectingOrganizations ? (
                        <>
                            <Tabs value={editingRosterSide} onChange={(_, v) => setEditingRosterSide(v)} centered>
                                <Tab label="Men's" value="mens" />
                                <Tab label="Women's" value="womens" />
                            </Tabs>
                            <Box hidden={editingRosterSide === "womens"}>
                                <WriteInTeamBox
                                    onClose={() => setWriteInOpen2(false)}
                                    home={false}
                                    roster={writeInMensRoster2.current}
                                    teamName={writeInTeamName2}
                                />
                            </Box>
                            <Box hidden={editingRosterSide === "mens"}>
                                <WriteInTeamBox
                                    onClose={() => setWriteInOpen2(false)}
                                    home={false}
                                    roster={writeInWomensRoster2.current}
                                    teamName={writeInTeamName2}
                                />
                            </Box>
                        </>
                    ) : (
                        <WriteInTeamBox
                            onClose={() => setWriteInOpen2(false)}
                            home={false}
                            roster={writeInRoster2}
                            teamName={writeInTeamName2}
                        />
                    )}
                </Box>
            </Modal>
        </TbTPage>
    );
};

export default DualMeetCreation;
