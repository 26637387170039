import { Grid, Card, Typography, CardContent, CardHeader, CardActionArea, Chip, Fade } from "@mui/material";
import { useEffect, useState } from "react";
import TbTPage from "../../../components/TbTPage/TbTPage";
import { Division, IOrganization, UserFlag } from "../../../types";
import geography from "../../../utils/geography";

import "./OrganizationList.css";
import useDatabase from "../../../hooks/database";
import useDivision from "../../../hooks/divison";
import { NCAADivisions } from "../../../utils/ncaaConference";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../utils/store";
import ErrorPage from "../NotFound/NotFound";
import { CommonLoading } from "../../../components/Loading/Loading";

const OrgDiv = ({ data }: { data: IOrganization }) => {
    const division = useDivision();

    const regionStr =
        division === Division.NCAA
            ? NCAADivisions.find(l => l.id === data.region)?.name || ""
            : `${data.region}, ${geography.find(l => l.countryCode === data.countryCode)?.country || "Unknown country"}`;

    return (
        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
            <Card variant="outlined">
                <CardActionArea href={`/school/${data.id}`} sx={{ textDecoration: "none !important" }}>
                    <CardHeader
                        title={
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                {data.logoPath && (
                                    <img
                                        style={{
                                            objectFit: "cover",
                                            width: 32,
                                            height: 32,
                                            marginRight: 16
                                        }}
                                        src={`https://firebasestorage.googleapis.com/v0/b/touchbytouch-b336e.appspot.com/o/${encodeURIComponent(data.logoPath)}?alt=media`}
                                    />
                                )}
                                {data.name}
                            </div>
                        }
                        sx={{ backgroundColor: data.color }}
                    />
                    <CardContent
                        sx={{
                            padding: "10px !important",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <Typography variant="body1" color="text.secondary">
                            {regionStr}
                        </Typography>
                        {!data.published && (
                            <Chip
                                sx={{
                                    fontFamily: "Lexend Deca",
                                    fontWeight: "500",
                                    marginLeft: "10px"
                                }}
                                size="small"
                                label="Unpublished"
                                color="error"
                            />
                        )}
                        {!data.administrators.length && (
                            <Chip
                                sx={{
                                    fontFamily: "Lexend Deca",
                                    fontWeight: "500",
                                    marginLeft: "10px"
                                }}
                                size="small"
                                label="Unmanaged"
                                color="warning"
                            />
                        )}
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
};

export default function OrganizationList() {
    const DB = useDatabase();

    const userInfo = useSelector((r: ReduxState) => r.userInfo);
    const [orgList, setOrgList] = useState<Record<string, IOrganization>>({});
    const [loading, setLoading] = useState(true);

    const [criticalError, setCriticalError] = useState("");

    useEffect(() => {
        (async () => {
            const result = await DB.getOrganizationList();
            if (result.status === "fail") return setCriticalError(result.data);
            const DBOrgList = result.data;
            if ((userInfo?.flags || 0) & UserFlag.UberAdmin) {
                setOrgList(DBOrgList);
                setLoading(false);
            } else {
                setOrgList(
                    Object.fromEntries(
                        Object.entries(DBOrgList).filter(
                            l =>
                                l[1].published ||
                                l[1].administrators.includes(userInfo?.id || "") ||
                                userInfo?.managingTeams.includes(l[1].boysTeam || "") ||
                                userInfo?.managingTeams.includes(l[1].girlsTeam || "")
                        )
                    )
                );
                setLoading(false);
            }
        })();
    }, []);

    if (criticalError) {
        return <ErrorPage message={criticalError} />;
    }

    const teamListArr = Object.values(orgList);
    teamListArr.sort((a, b) => a.name.localeCompare(b.name));

    return (
        <TbTPage>
            <div style={{ height: 50 }}></div>
            <Typography fontFamily="Lexend Deca" component="h1" variant="h3">
                Schools
            </Typography>
            {loading && <CommonLoading />}
            <Fade in={!loading}>
                <Grid
                    container
                    spacing={3}
                    sx={{
                        width: "90vw",
                        margin: "0 auto 20px auto",
                        paddingRight: "20px"
                    }}
                >
                    {teamListArr.map(l => (
                        <OrgDiv data={l} key={`orgListDiv${l.id}`} />
                    ))}
                </Grid>
            </Fade>
            <div style={{ height: 1 }}></div>
        </TbTPage>
    );
}
