import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";

import Navigation from "./components/college/Navigation";
import Footer from "./components/college/Footer";

import LiveNow from "./pages/college/LiveNow/LiveNow";
import About from "./pages/college/About/About";
import Login from "./pages/college/Login/Login";
import EventCreation from "./pages/college/EventCreation/EventCreation";
import OrgCreation from "./pages/college/OrganizationCreation/OrganizationCreation";
import DualMeets from "./pages/college/DualMeets/DualMeets";
import TeamList from "./pages/college/TeamList/TeamList";
import TeamDetail from "./pages/college/TeamDetail/TeamDetail";
import OrganizationList from "./pages/college/OrganizationList/OrganizationList";
import OrganizationDetail from "./pages/college/OrganizationDetail/OrganizationDetail";
import RosterEditor from "./pages/college/RosterEditor/RosterEditor";
import DualMeetCreation from "./pages/college/DualMeetCreation/DualMeetCreation";
import DualMeetInfo from "./pages/college/DualMeetInfo/DualMeetInfo";
import DualMeetScorer from "./pages/college/DualMeetScorer/DualMeetScorer";
import FencerInfo from "./pages/college/FencerInfo/FencerInfo";
import SuperadminPage from "./pages/college/SuperadminPage/SuperadminPage";
import PrivacyPolicy from "./pages/college/PrivacyPolicy/PrivacyPolicy";
import Events from "./pages/college/Events/Events";
import EventInfo from "./pages/college/EventInfo/EventInfo";
import ErrorPage from "./pages/college/NotFound/NotFound";

import "./Home.css";

const lightTheme = createTheme({
    palette: {
        mode: "light"
    },
    components: {
        MuiCardHeader: {
            defaultProps: {
                titleTypographyProps: {
                    fontFamily: "Lexend Deca",
                    fontWeight: 500
                },
                sx: {
                    background: theme => theme.palette.primary.light
                }
            }
        }
    }
});

const Home = () => {
    return (
        <ThemeProvider theme={lightTheme}>
            <CssBaseline />
            <Router basename={process.env.PUBLIC_URL}>
                <Navigation />
                <Switch>
                    <Route path="/" exact render={() => <LiveNow></LiveNow>} />
                    <Route path="/about" exact render={() => <About></About>} />
                    <Route path="/privacy" exact render={() => <PrivacyPolicy />} />
                    <Route path="/login" exact render={() => <Login />} />
                    <Route path="/createEvent" exact render={() => <EventCreation />} />
                    <Route path="/createDualMeet" exact render={() => <DualMeetCreation />} />
                    <Route path="/createSchool" exact render={() => <OrgCreation />} />
                    <Route path="/events" exact render={() => <Events />} />
                    <Route path="/dualMeets" exact render={() => <DualMeets />} />
                    <Route path="/eventInfo/:id" exact render={() => <EventInfo />} />
                    <Route path="/meet/:id" exact render={() => <DualMeetInfo />} />
                    <Route path="/dualMeetScorer/:id" render={() => <DualMeetScorer />} />
                    <Route path="/schoolList" exact render={() => <OrganizationList />} />
                    <Route path="/school/:id" exact render={() => <OrganizationDetail />} />
                    <Route path="/teamList" exact render={() => <TeamList />} />
                    <Route path="/team/:id" exact render={() => <TeamDetail />} />
                    <Route path="/rosterEditor/:teamId" exact render={() => <RosterEditor />} />
                    <Route path="/fencer/:id" exact render={() => <FencerInfo />} />
                    <Route path="/superadminPage" exact render={() => <SuperadminPage />} />
                    <Route path="*" render={() => <ErrorPage code={404} message="Page not found." />} />
                </Switch>
                <Footer />
            </Router>
        </ThemeProvider>
    );
};

export default Home;
