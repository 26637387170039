import { Box, Typography, Container } from "@mui/material";

export default function Footer() {
    return (
        <Box component="footer" sx={{ backgroundColor: "#EEE", py: "10px", height: "65px" }}>
            <Container maxWidth="lg">
                <Typography variant="body1" align="center" fontFamily="Lexend Deca">
                    Contact us:{" "}
                    <a href="mailto:tbt@redpandafencing.org" style={{ color: "inherit" }}>
                        tbt@redpandafencing.org
                    </a>
                </Typography>
                <Typography
                    variant="subtitle2"
                    align="center"
                    color="text.secondary"
                    component="p"
                    fontWeight="400"
                    fontFamily="Lexend Deca"
                >
                    Sponsored by Red Panda Fencing Inc.
                </Typography>
            </Container>
        </Box>
    );
}
