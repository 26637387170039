import { Card, CardActionArea, CardContent, Typography, Chip } from "@mui/material";
import { DualMeetType, ICollegeEvent } from "../types";

const CollegeEventInfo = ({ data }: { data: ICollegeEvent }) => {
    return (
        <Card
            sx={{
                margin: "0 auto 20px auto",
                textAlign: "left",
                flexShrink: 0,
                maxWidth: "100%"
            }}
        >
            <CardActionArea href={`/eventInfo/${data.id}`} sx={{ textDecoration: "none !important" }}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {data.name}
                        {!data.published && (
                            <Chip
                                label="Unpublished"
                                style={{
                                    backgroundColor: "red",
                                    marginLeft: 10,
                                    color: "white",
                                    fontFamily: "Lexend Deca"
                                }}
                            />
                        )}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        {data.startedAt ? new Date(data.startedAt).toDateString() : "No start date"}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        {data.location}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default CollegeEventInfo;
