import { Typography, Paper, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import TbTPage from "../../../components/TbTPage/TbTPage";
import { ICollegeEvent } from "../../../types";
import useDatabase from "../../../hooks/database";

import "./LiveNow.css";
import CollegeEventInfo from "../../../components/CollegeEventInfo";
import { DBResult } from "../../../utils/database";
import SnackbarError from "../../../components/SnackbarError";
import RosterAutoUpdateDialog from "../../../components/RosterAutoUpdateDialog";

const LiveNow = () => {
    const DB = useDatabase();

    const [liveCollegeEvents, setLiveCollegeEvents] = useState<ICollegeEvent[]>([]);
    const [snackbarError, setSnackbarError] = useState("");

    const handleEvents = (events: DBResult<ICollegeEvent[]>) => {
        if (events.status === "fail") return setSnackbarError(events.data);
        setLiveCollegeEvents(events.data);
    };

    useEffect(() => {
        DB.getLiveCollegeEvents(handleEvents);

        return () => {
            // DB.stoplisteningLiveEvents();
        };
    }, []);

    return (
        <TbTPage className="landingPage">
            <Typography fontFamily="Lexend Deca" fontSize="2.5rem">
                Welcome to Touch By Touch
            </Typography>
            <br />
            <Typography fontFamily="Lexend Deca" variant="h4" sx={{ marginBottom: "10px" }}>
                Live Now
            </Typography>
            {!liveCollegeEvents.length && (
                <Paper
                    sx={{
                        padding: "20px",
                        width: "90vw",
                        maxWidth: "500px",
                        margin: "auto"
                    }}
                >
                    <Typography variant="h5">There are no live meets right now. Check back later!</Typography>
                </Paper>
            )}
            <Grid container spacing={3} style={{ width: "90vw", margin: "auto" }}>
                {liveCollegeEvents.map(meet => (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={`liveNowMeet${meet.id}`}>
                        <CollegeEventInfo key={`liveNowasdfas${meet.id}`} data={meet} />
                    </Grid>
                ))}
            </Grid>
            <RosterAutoUpdateDialog />
            <SnackbarError error={snackbarError} close={() => setSnackbarError("")} />
        </TbTPage>
    );
};

export default LiveNow;
