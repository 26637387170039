import { Box, Card, CardActionArea, Typography } from "@mui/material";

export const CreateMeetCard = ({ startCreatingMeet }: { startCreatingMeet: () => void }) => {
    return (
        <Card style={{ margin: "5px 10px 5px 0", width: 160 }} variant="outlined">
            <CardActionArea onClick={startCreatingMeet} sx={{ textDecoration: "none !important", height: "100%" }}>
                <Box
                    sx={{
                        padding: "5px 10px",
                        display: "flex",
                        alignItems: "center",
                        height: "100%"
                    }}
                >
                    <Typography style={{ textAlign: "center", flex: 1, color: "#666" }}>Add meet</Typography>
                </Box>
            </CardActionArea>
        </Card>
    );
};
