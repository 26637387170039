import { Box, Fade, TextField, Typography } from "@mui/material";
import TbTPage from "../../../components/TbTPage/TbTPage";
import { useEffect, useState } from "react";
import useDatabase from "../../../hooks/database";
import { ICollegeEvent, UserFlag } from "../../../types";
import CollegeEventInfo from "../../../components/CollegeEventInfo";
import { CommonLoading } from "../../../components/Loading/Loading";
import { getTeamIDsFromEvent } from "../../../utils/helpers";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../utils/store";
import { isSuccess } from "../../../utils/database";
import ErrorPage from "../NotFound/NotFound";

const Events = () => {
    const DB = useDatabase();

    const userInfo = useSelector((s: ReduxState) => s.userInfo);
    const [eventsRaw, setEventsRaw] = useState<ICollegeEvent[]>([]);
    const [loading, setLoading] = useState(true);
    const [eventFilter, setEventFilter] = useState("");
    const [criticalError, setCriticalError] = useState("");

    useEffect(() => {
        DB.getCollegeEventsRaw().then(async v => {
            if (v.status === "fail") return setCriticalError(v.data);
            const values = Object.values(v.data);
            const linkedFencers = userInfo ? await Promise.all(userInfo.linkedFencerIds.map(l => DB.getFencer(l))) : [];
            const linkedTeams = linkedFencers.filter(isSuccess).flatMap(l => l.data.teams);
            const userTeams = userInfo ? [...userInfo.teams, ...linkedTeams] : [];
            if ((userInfo?.flags || 0) & UserFlag.MeetManager) {
                setEventsRaw(values);
            } else {
                // TODO: Check for managers as well
                setEventsRaw(
                    values.filter(event => {
                        if (event.published) return true;
                        const teams = getTeamIDsFromEvent(event);
                        return userTeams.some(l => teams.includes(l)) || false;
                    })
                );
            }
            setLoading(false);
        });
    }, []);

    if (criticalError) {
        return <ErrorPage message={criticalError} />;
    }

    const events = eventsRaw
        .filter(l => l.name.toLowerCase().includes(eventFilter.toLowerCase()))
        .sort((a, b) => Number(b.startedAt) - Number(a.startedAt));

    return (
        <TbTPage className="eventsPage">
            <div style={{ height: 50 }}></div>
            <Typography fontFamily="Lexend Deca" component="h1" variant="h3" sx={{ marginBottom: "10px" }}>
                College Events
            </Typography>
            <div
                style={{
                    display: "flex",
                    marginTop: "20px",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <TextField
                    label="Search"
                    placeholder="Enter a meet name..."
                    variant="outlined"
                    sx={{ marginRight: "10px" }}
                    value={eventFilter}
                    onChange={e => setEventFilter(e.target.value)}
                />
            </div>
            {loading && <CommonLoading />}
            <Fade in={!loading}>
                <Box
                    sx={{
                        width: "90%",
                        maxWidth: "500px",
                        margin: "20px auto"
                    }}
                >
                    {events.map(l => (
                        <CollegeEventInfo key={`collegeEventInfo${l.id}`} data={l} />
                    ))}
                </Box>
            </Fade>
            <div style={{ height: 1 }}></div>
        </TbTPage>
    );
};

export default Events;
