import { useEffect, useState } from "react";
import EquipmentOnGround from "../../../assets/EquipmentOnGround.jpg";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../utils/store";
import PermissionDenied from "../../../components/PermissionDenied/PermissionDenied";
import TbTPage from "../../../components/TbTPage/TbTPage";
import {
    Button,
    Card,
    CardContent,
    CardMedia,
    Checkbox,
    Chip,
    FormControl,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    Box,
    CardHeader,
    Modal,
    Grid,
    Typography,
    Radio,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Link,
    DialogActions,
    SelectChangeEvent
} from "@mui/material";
import { modalBoxStyle } from "../../..";
import useDatabase from "../../../hooks/database";
import { Conference, NCAADivisions, NCAAMensConferences, NCAARegions, NCAAWomensConferences } from "../../../utils/ncaaConference";
import { CollegeProgramType, IOrganization, SchoolType } from "../../../types";
import "./OrganizationCreation.css";
import { schoolNamesEqual } from "../../../utils/helpers";
import { DBResult } from "../../../utils/database";
import ErrorPage from "../NotFound/NotFound";
import SnackbarError from "../../../components/SnackbarError";

const OrganizationCreation = () => {
    const defaultDivision = { name: "Select division", id: "" };
    const defaultRegion = { name: "Select region", id: "" };

    const [division, setDivision] = useState(defaultDivision);
    const [region, setRegion] = useState(defaultRegion);
    const [boysConference, setBoysConference] = useState<Conference[]>([]);
    const [girlsConference, setGirlsConference] = useState<Conference[]>([]);
    const [organizationName, setOrganizationName] = useState("");
    const [organizationAbbreviation, setOrganizationAbbreviation] = useState("");
    const [boysTeam, setBoysTeam] = useState(CollegeProgramType.None);
    const [girlsTeam, setGirlsTeam] = useState(CollegeProgramType.None);
    const [boysJV, setBoysJV] = useState(false);
    const [girlsJV, setGirlsJV] = useState(false);
    const [confirming, setConfirming] = useState(false);

    const [schools, setSchools] = useState<Record<string, IOrganization>>({});
    const [duplicateOpen, setDuplicateOpen] = useState(false);
    const [duplicateStr, setDuplicateStr] = useState("");
    const [duplicateID, setDuplicateID] = useState("");

    const [criticalError, setCriticalError] = useState("");
    const [formError, setFormError] = useState("");

    const history = useHistory();
    const DB = useDatabase();

    const userInfo = useSelector((s: ReduxState) => s.userInfo);

    const handleSchools = (schools: DBResult<Record<string, IOrganization>>) => {
        if (schools.status === "fail") return setCriticalError(schools.data);
        setSchools(schools.data);
    };

    useEffect(() => {
        DB.getOrganizationList(handleSchools);

        return () => {
            DB.stopListeningOrganizationList(handleSchools);
        };
    }, []);

    if (!userInfo) {
        return <PermissionDenied message="You are not logged in!" />;
    }

    if (criticalError) {
        return <ErrorPage message={criticalError} />;
    }

    const handleMensConferenceSelectChange = (event: SelectChangeEvent<string[]>) => {
        const newSelectionIds = typeof event.target.value === "string" ? event.target.value.split(",") : event.target.value;
        const newSelection = newSelectionIds.map(n => NCAAMensConferences.find(conf => conf.id === n));
        setBoysConference([...newSelection.filter(Boolean)] as Conference[]);
    };

    const handleWomensConferenceSelectChange = (event: SelectChangeEvent<string[]>) => {
        const newSelectionIds = typeof event.target.value === "string" ? event.target.value.split(",") : event.target.value;
        const newSelection = newSelectionIds.map(n => NCAAWomensConferences.find(conf => conf.id === n));
        setGirlsConference([...newSelection.filter(Boolean)] as Conference[]);
    };

    const submitForm = async () => {
        const result = await DB.createOrganization(
            organizationName,
            "US",
            division.id,
            {
                boysTeam,
                girlsTeam,
                boysTeamJV: boysJV,
                girlsTeamJV: girlsJV,
                boysTeamConference: boysConference.map(conf => conf.id),
                girlsTeamConference: girlsConference.map(conf => conf.id)
            },
            organizationAbbreviation,
            SchoolType.HS,
            false,
            region.id
        );
        if (result.status === "fail") return setFormError(result.data);
        history.push(`/school/${result.data.id}`);
    };

    const tryConfirm = () => {
        for (const id in schools) {
            if (schoolNamesEqual(schools[id].name, organizationName) && schools[id].region === division.id) {
                setDuplicateID(id);
                // Technically this isn't necessary, but maybe in the future we will have near-matches (Morris Hills vs Morris Hills High School)
                setDuplicateStr(schools[id].name);
                setDuplicateOpen(true);
                return;
            }
        }

        setConfirming(true);
    };

    const Confirmation = () => {
        const both = boysTeam !== CollegeProgramType.None && girlsTeam !== CollegeProgramType.None;
        return (
            <Dialog open={confirming} onClose={() => setConfirming(false)}>
                <DialogTitle>Confirm School Creation</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to create this school?{" "}
                        <strong>
                            This action will create a{" "}
                            {both ? "men's team and a women's team" : `${boysTeam !== CollegeProgramType.None ? "men's" : "women's"} team only`}!
                        </strong>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setConfirming(false);
                        }}
                    >
                        Go back
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            submitForm();
                            setConfirming(false);
                        }}
                        autoFocus
                    >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const disabled =
        !organizationName.trim() ||
        division.id === "" ||
        region.id === "" ||
        (!boysTeam && !girlsTeam) ||
        !organizationAbbreviation ||
        !Object.keys(schools).length ||
        (boysTeam !== CollegeProgramType.None && (!boysConference || !NCAAMensConferences.find(c => boysConference.includes(c)))) ||
        (girlsTeam !== CollegeProgramType.None && (!girlsConference || !NCAAWomensConferences.find(c => girlsConference.includes(c))));

    return (
        <TbTPage className="orgCreationPage">
            <Card
                sx={{
                    width: "90%",
                    maxWidth: 1000,
                    margin: "10vh auto 30px auto"
                }}
            >
                <CardHeader
                    title="Create New School"
                    titleTypographyProps={{
                        variant: "h4",
                        textAlign: "center",
                        fontFamily: "Lexend Deca"
                    }}
                />
                <CardContent
                    sx={{
                        display: "flex",
                        padding: "0 !important",
                        height: 475
                    }}
                >
                    <Box
                        sx={{
                            minWidth: 300,
                            width: 450,
                            flexShrink: 0.5,
                            padding: "10px 15px",
                            overflow: "auto"
                        }}
                    >
                        <FormControl style={{ marginTop: 10 }} fullWidth>
                            <TextField
                                label="School Name"
                                placeholder="School Name (e.g. Morris Hills High School)"
                                size="small"
                                value={organizationName}
                                onChange={e => setOrganizationName(e.target.value)}
                                fullWidth
                            />
                        </FormControl>
                        <FormControl style={{ marginTop: 15, marginBottom: 10 }} fullWidth>
                            <TextField
                                label="School Abbreviation"
                                placeholder="School Abbreviation (e.g. MHHS)"
                                size="small"
                                value={organizationAbbreviation}
                                onChange={e => setOrganizationAbbreviation(e.target.value)}
                                fullWidth
                            />
                        </FormControl>
                        <Grid container columns={3}>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={1}>
                                <Typography textAlign="center">Men's</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography textAlign="center">Women's</Typography>
                            </Grid>
                            <Grid
                                item
                                xs={1}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <Typography>Varsity</Typography>
                            </Grid>
                            <Grid item xs={1} sx={{ textAlign: "center" }}>
                                <Radio
                                    checked={boysTeam === CollegeProgramType.Varsity}
                                    onChange={() => setBoysTeam(CollegeProgramType.Varsity)}
                                />
                            </Grid>
                            <Grid item xs={1} sx={{ textAlign: "center" }}>
                                <Radio
                                    checked={girlsTeam === CollegeProgramType.Varsity}
                                    onChange={() => setGirlsTeam(CollegeProgramType.Varsity)}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={1}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <Typography>Club</Typography>
                            </Grid>
                            <Grid item xs={1} sx={{ textAlign: "center" }}>
                                <Radio
                                    checked={boysTeam === CollegeProgramType.Club}
                                    onChange={() => setBoysTeam(CollegeProgramType.Club)}
                                />
                            </Grid>
                            <Grid item xs={1} sx={{ textAlign: "center" }}>
                                <Radio
                                    checked={girlsTeam === CollegeProgramType.Club}
                                    onChange={() => setGirlsTeam(CollegeProgramType.Club)}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={1}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <Typography>JV</Typography>
                            </Grid>
                            <Grid item xs={1} sx={{ textAlign: "center" }}>
                                <Checkbox
                                    disabled={boysTeam === CollegeProgramType.None}
                                    checked={boysJV}
                                    onChange={() => setBoysJV(u => !u)}
                                />
                            </Grid>
                            <Grid item xs={1} sx={{ textAlign: "center" }}>
                                <Checkbox
                                    disabled={girlsTeam === CollegeProgramType.None}
                                    checked={girlsJV}
                                    onChange={() => setGirlsJV(u => !u)}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={1}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <Typography>None</Typography>
                            </Grid>
                            <Grid item xs={1} sx={{ textAlign: "center" }}>
                                <Radio
                                    checked={boysTeam === CollegeProgramType.None}
                                    onChange={() => {
                                        setBoysTeam(CollegeProgramType.None);
                                        setBoysJV(false);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1} sx={{ textAlign: "center" }}>
                                <Radio
                                    checked={girlsTeam === CollegeProgramType.None}
                                    onChange={() => {
                                        setGirlsTeam(CollegeProgramType.None);
                                        setGirlsJV(false);
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <FormControl fullWidth size="small" sx={{ marginTop: "10px" }}>
                            <InputLabel id="division-select">Division Name</InputLabel>
                            <Select
                                labelId="division-select"
                                id="division-select"
                                label="Division Name"
                                value={division.id}
                                onChange={e => setDivision(NCAADivisions.find(l => l.id === e.target.value) || defaultDivision)}
                            >
                                {NCAADivisions.map(l => (
                                    <MenuItem value={l.id} key={`newTeamDivision${l.id}`}>
                                        {l.name || "Select division"}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth size="small" sx={{ marginTop: "10px" }}>
                            <InputLabel id="region-select">Region Name</InputLabel>
                            <Select
                                labelId="region-select"
                                id="region-select"
                                label="Region Name"
                                value={region.id}
                                onChange={e => setRegion(NCAARegions.find(l => l.id === e.target.value) || defaultRegion)}
                            >
                                {NCAARegions.map(l => (
                                    <MenuItem value={l.id} key={`newTeamRegion${l.id}`}>
                                        {l.name || "Select region"}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {boysTeam !== CollegeProgramType.None && (
                            <FormControl fullWidth size="small" sx={{ marginTop: "10px" }}>
                                <InputLabel id="mens-conference-select">Men's Conference</InputLabel>
                                <Select
                                    labelId="mens-conference-select"
                                    id="mens-conference-select"
                                    label="Men's Conference"
                                    value={boysConference.map(conf => conf.id)}
                                    multiple
                                    renderValue={selected => (
                                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                            {selected.map(value => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                    onChange={handleMensConferenceSelectChange}
                                >
                                    {NCAAMensConferences.map(l => (
                                        <MenuItem value={l.id} key={`newTeamMensConference${l.id}`}>
                                            <Checkbox checked={boysConference.includes(l)} />
                                            {l.name || "Select conference"}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                        {girlsTeam !== CollegeProgramType.None && (
                            <FormControl fullWidth size="small" sx={{ marginTop: "10px" }}>
                                <InputLabel id="womens-conference-select">Women's Conference</InputLabel>
                                <Select
                                    labelId="womens-conference-select"
                                    id="womens-conference-select"
                                    label="Women's Conference"
                                    value={girlsConference.map(conf => conf.id)}
                                    multiple
                                    renderValue={selected => (
                                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                            {selected.map(value => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                    onChange={handleWomensConferenceSelectChange}
                                >
                                    {NCAAWomensConferences.map(l => (
                                        <MenuItem value={l.id} key={`newTeamWomensConference${l.id}`}>
                                            <Checkbox checked={girlsConference.includes(l)} />
                                            {l.name || "Select conference"}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                        <Button
                            sx={{ margin: "10px 0 0 0" }}
                            fullWidth
                            variant="contained"
                            disabled={disabled}
                            className={`${disabled ? "disabledButton" : ""}`}
                            onClick={tryConfirm}
                        >
                            Create
                        </Button>
                    </Box>
                    <CardMedia component="img" image={EquipmentOnGround} alt="Old fencers" />
                </CardContent>
            </Card>
            <Confirmation />
            <Dialog open={duplicateOpen} onClose={() => setDuplicateOpen(false)}>
                <DialogTitle>School Name Conflict</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Another {division.name} school already exists with the name {duplicateStr}. If it is unmanaged, you can request to
                        manage it on the <Link href={`/school/${duplicateID}`}>school's page</Link>. If you think this message is in error,
                        please contact TbT admins.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setDuplicateOpen(false);
                            setConfirming(true);
                        }}
                    >
                        Create anyways
                    </Button>
                    <Button variant="contained" onClick={() => setDuplicateOpen(false)} autoFocus>
                        I understand
                    </Button>
                </DialogActions>
            </Dialog>
            <SnackbarError error={formError} close={() => setFormError("")} />
        </TbTPage>
    );
};

export default OrganizationCreation;
