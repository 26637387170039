import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { modalBoxStyle, modalBoxStyleNarrower } from "../../..";

import { CommonLoading } from "../../../components/Loading/Loading";
import TbTPage from "../../../components/TbTPage/TbTPage";
import TeamCard from "../../../components/TeamCard";
import { IOrganization, IPublicationApplication, ITeam, IUser, SchoolType, UserFlag } from "../../../types";
import { ReduxState } from "../../../utils/store";
import useDatabase from "../../../hooks/database";

import "./OrganizationDetail.css";
import {
    NCAADivisions,
    Conference,
    NCAAMensConferences,
    NCAAWomensConferences,
    NCAARegion,
    NCAARegions
} from "../../../utils/ncaaConference";
import {
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography
} from "@mui/material";
import ErrorPage from "../NotFound/NotFound";
import { genAbbreviationFromName } from "../../../utils/helpers";
import { DBResult } from "../../../utils/database";
import SnackbarError from "../../../components/SnackbarError";
import { ERROR_SCHOOL_DOES_NOT_EXIST } from "../../../utils/constants";

import FileStorage from "../../../utils/fileStorage";
import InputFileUpload from "../../../components/InputFileUpload/InputFileUpload";

export default function OrganizationDetail() {
    const { id } = useParams<{ id: string }>();
    const DB = useDatabase();
    const history = useHistory();

    const defaultConference = { name: "Select conference", id: "select" };
    const defaultRegion = { name: "Select region", id: "select" };

    const [criticalError, setCriticalError] = useState("");
    const [snackbarError, setSnackbarError] = useState("");

    const [uploadedLogo, setUploadedLogo] = useState<File | null>(null);
    const [previewLogo, setPreviewLogo] = useState<string | undefined>(undefined);
    const [deletingLogo, setDeletingLogo] = useState<boolean>(false);
    // Use when user has just uploaded a new logo to prevent empty image from showing up
    const [justUploadedLogo, setJustUploadedLogo] = useState<string | undefined>(undefined);

    const userInfo = useSelector((s: ReduxState) => s.userInfo);
    const [organizationData, setOrganizationData] = useState<IOrganization | null>(null);
    const [organizationRequests, setOrganizationRequests] = useState<Record<string, IPublicationApplication>>({});
    const [organizationUser, setOrganizationUser] = useState<IUser | null>(null);
    const [requestingTakeover, setRequestingTakeover] = useState(false);
    const [requestingOrganization, setRequestingOrganization] = useState(false);
    const [requestLoading, setRequestLoading] = useState(false);
    const [requestName, setRequestName] = useState("");
    const [requestSchool, setRequestSchool] = useState("");
    const [requestAddInf, setRequestAddInf] = useState("");

    const [makingUnmanaged, setMakingUnmanaged] = useState(false);
    const [unpublishingOpen, setUnpublishingOpen] = useState(false);

    const [configOpen, setConfigOpen] = useState(false);
    const [boysTeam, setBoysTeam] = useState<ITeam | null>(null);
    const [girlsTeam, setGirlsTeam] = useState<ITeam | null>(null);
    const [boysTeamJV, setBoysTeamJV] = useState<ITeam | null>(null);
    const [girlsTeamJV, setGirlsTeamJV] = useState<ITeam | null>(null);

    const [region, setRegion] = useState<NCAARegion | undefined>(undefined);
    const [boysTeamConf, setBoysTeamConf] = useState<Conference[]>([]);
    const [girlsTeamConf, setGirlsTeamConf] = useState<Conference[]>([]);

    const [newTeamConf, setNewTeamConf] = useState<Conference[]>([]);

    const [converting, setConverting] = useState<"mens" | "womens" | "">("");
    const [convertingRegion, setConvertingRegion] = useState("");
    const convertingTeam = converting === "mens" ? boysTeam : converting === "womens" ? girlsTeam : undefined;

    const [deleting, setDeleting] = useState<"mens" | "womens" | "mensJV" | "womensJV" | "">("");
    const deletingTeam =
        deleting === "mens"
            ? boysTeam
            : deleting === "womens"
              ? girlsTeam
              : deleting === "mensJV"
                ? boysTeamJV
                : deleting === "womensJV"
                  ? girlsTeamJV
                  : undefined;

    const [creating, setCreating] = useState<"" | "boys" | "girls" | "boysJV" | "girlsJV">("");

    const handleOrganizationRequests = (result: DBResult<Record<string, IPublicationApplication>>) => {
        if (result.status === "fail") return setCriticalError(result.data);
        setOrganizationRequests(result.data);
    };

    const handleOrganizationData = (result: DBResult<IOrganization>) => {
        if (result.status === "fail") return setCriticalError(result.data);
        setOrganizationData(result.data);
    };

    useEffect(() => {
        DB.getOrganizationRequests(handleOrganizationRequests);
        DB.getOrganization(id, handleOrganizationData).then(org => {
            if (org.status === "fail") return setCriticalError(org.data);
            setRegion(NCAARegions.find(r => r.id === org.data.district));
        });

        return () => {
            DB.stopListeningOrganizationRequests();
            DB.stopListeningOrganization(id, handleOrganizationData);
        };
    }, []);

    useEffect(() => {
        try {
            if (organizationData?.createdBy) {
                DB.getUserInfo(organizationData.createdBy).then(r => {
                    if (r.status === "fail") return;
                    setOrganizationUser(r.data);
                });
            } else {
                setOrganizationUser(null);
            }
        } catch (e: unknown) {
            console.log(e);
        }
    }, [organizationData?.createdBy]);

    const updateTeamsData = async () => {
        boys: if (organizationData?.boysTeam) {
            const team = await DB.getTeam(organizationData.boysTeam);
            if (team.status === "fail") {
                setSnackbarError(team.data);
                break boys;
            }
            setBoysTeam(team.data);
            setBoysTeamConf([...NCAAMensConferences.filter(l => team.data.conference?.includes(l.id))]);
        }
        girls: if (organizationData?.girlsTeam) {
            const team = await DB.getTeam(organizationData.girlsTeam);
            if (team.status === "fail") {
                setSnackbarError(team.data);
                break girls;
            }
            setGirlsTeam(team.data);
            setGirlsTeamConf([...NCAAWomensConferences.filter(l => team.data.conference?.includes(l.id))]);
        }
        boysJV: if (organizationData?.boysJVTeam) {
            const team = await DB.getTeam(organizationData.boysJVTeam);
            if (team.status === "fail") {
                setSnackbarError(team.data);
                break boysJV;
            }
            setBoysTeamJV(team.data);
        }
        girlsJV: if (organizationData?.girlsJVTeam) {
            const team = await DB.getTeam(organizationData.girlsJVTeam);
            if (team.status === "fail") {
                setSnackbarError(team.data);
                break girlsJV;
            }
            setGirlsTeamJV(team.data);
        }
    };

    useEffect(() => {
        updateTeamsData();
    }, [organizationData?.boysTeam, organizationData?.girlsTeam, organizationData?.boysJVTeam, organizationData?.girlsJVTeam]);

    if (!organizationData) {
        return (
            <TbTPage>
                <CommonLoading />
            </TbTPage>
        );
    }

    if (criticalError === ERROR_SCHOOL_DOES_NOT_EXIST) {
        return <ErrorPage code={404} message="The requested school could not be found." />;
    } else if (criticalError) {
        return <ErrorPage message={criticalError} />;
    }

    const unpublish = async () => {
        const result = await DB.unpublishOrganization(id);
        if (result.status === "fail") {
            setSnackbarError(result.data);
        } else {
            setUnpublishingOpen(false);
        }
    };

    const makeUnmanaged = () => {
        DB.makeOrganizationUnmanaged(organizationData).then(() => setMakingUnmanaged(false));
    };

    const startConversion = (team: "mens" | "womens") => {
        const matchingTeam = team === "mens" ? boysTeam : girlsTeam;
        if (!matchingTeam) return;
        setConverting(team);
        setConvertingRegion(matchingTeam.region === "club" ? "div1" : "club");
    };

    const convert = () => {
        if (!convertingTeam || !convertingRegion) return;
        DB.convertTeam(convertingTeam?.id, convertingRegion).then(() => {
            setConverting("");
            updateTeamsData();
        });
    };

    const deleteTeam = () => {
        if (!deletingTeam) return;
        DB.deleteTeam(deletingTeam.id);
        setDeleting("");
    };

    const handleMensConferenceSelectChange = (event: SelectChangeEvent<string[]>) => {
        const newSelectionIds = typeof event.target.value === "string" ? event.target.value.split(",") : event.target.value;
        const newSelection = newSelectionIds.map(n => NCAAMensConferences.find(conf => conf.id === n));
        setBoysTeamConf([...newSelection.filter(Boolean)] as Conference[]);
    };

    const handleWomensConferenceSelectChange = (event: SelectChangeEvent<string[]>) => {
        const newSelectionIds = typeof event.target.value === "string" ? event.target.value.split(",") : event.target.value;
        const newSelection = newSelectionIds.map(n => NCAAWomensConferences.find(conf => conf.id === n));
        setGirlsTeamConf([...newSelection.filter(Boolean)] as Conference[]);
    };

    const handleNewTeamConferenceSelectChange = (event: SelectChangeEvent<string[]>) => {
        const newSelectionIds = typeof event.target.value === "string" ? event.target.value.split(",") : event.target.value;
        const newSelection = newSelectionIds.map(n =>
            (creating.includes("girls") ? NCAAWomensConferences : NCAAMensConferences).find(conf => conf.id === n)
        );
        setNewTeamConf([...newSelection.filter(Boolean)] as Conference[]);
    };

    const updateConferences = () => {
        if (organizationData?.boysTeam && boysTeamConf) {
            DB.updateTeamConference(organizationData.boysTeam, [...boysTeamConf.map(conf => conf.id)]);
        }
        if (organizationData?.girlsTeam && girlsTeamConf) {
            DB.updateTeamConference(organizationData.girlsTeam, [...girlsTeamConf.map(conf => conf.id)]);
        }
        if (organizationData?.boysJVTeam && boysTeamConf) {
            DB.updateTeamConference(organizationData.boysJVTeam, [...boysTeamConf.map(conf => conf.id)]);
        }
        if (organizationData?.girlsJVTeam && girlsTeamConf) {
            DB.updateTeamConference(organizationData.girlsJVTeam, [...girlsTeamConf.map(conf => conf.id)]);
        }

        if (region && region.id !== "select") {
            DB.editOrganization({ id: organizationData.id, district: region.id });
        }

        setConfigOpen(false);
    };

    const previewSchoolLogo = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }

        const file = event.target.files[0];

        if (!file.type.startsWith("image/")) {
            setSnackbarError("Uploaded file must be an image.");
            return;
        }

        if (file.size > 3 * 1024 * 1024) {
            setSnackbarError("Uploaded file must be less than 3MB.");
            return;
        }

        setUploadedLogo(file);
        setPreviewLogo(URL.createObjectURL(file));
    };

    const uploadSchoolLogo = async () => {
        if (!uploadedLogo) {
            return;
        }

        if (organizationData.logoPath) {
            await FileStorage.deleteFile(organizationData.logoPath);
        }

        const path = await FileStorage.upload(uploadedLogo);
        await DB.editOrganization({ id: organizationData.id, logoPath: path });

        setJustUploadedLogo(previewLogo);

        organizationData.logoPath = path;
        setOrganizationData(organizationData);

        setUploadedLogo(null);
        setPreviewLogo(undefined);
        setConfigOpen(false);
    };

    const removeSchoolLogo = async () => {
        if (!organizationData.logoPath) {
            return;
        }

        await FileStorage.deleteFile(organizationData.logoPath);

        organizationData.logoPath = "";
        setOrganizationData(organizationData);

        DB.editOrganization({ id: organizationData.id, logoPath: "" });

        setDeletingLogo(false);
    };

    const showCreateTeamDialogIfNeeded = async (team: "boys" | "girls" | "boysJV" | "girlsJV") => {
        if (team.startsWith("boys")) {
            if (boysTeamConf && boysTeamConf.length > 0) {
                await createTeamImpl(team, boysTeamConf);
                return;
            }
        } else if (team.startsWith("girls")) {
            if (girlsTeamConf && girlsTeamConf.length > 0) {
                await createTeamImpl(team, girlsTeamConf);
                return;
            }
        }
        setCreating(team);
    };

    const createTeam = async () => {
        await createTeamImpl(creating, newTeamConf);
    };

    const createTeamImpl = async (creating: "" | "boys" | "girls" | "boysJV" | "girlsJV", newTeamConf: Conference[]) => {
        if (!organizationData || !creating) return;

        const team = creating;
        let name = organizationData.name;
        let abbreviation = organizationData.abbreviation || genAbbreviationFromName(organizationData.name);
        if (team.startsWith("boys")) {
            name += " Men's";
            abbreviation += " Men's";
        }
        if (team.startsWith("girls")) {
            name += " Women's";
            abbreviation += " Women's";
        }
        if (team.includes("JV")) {
            name += " - JV";
            abbreviation += " JV";
        }

        const newID = await DB.createTeamAttachedToOrg(
            organizationData.id,
            name,
            abbreviation,
            organizationData.countryCode,
            organizationData.region,
            `${team}Team`,
            organizationData.type,
            {
                published: organizationData.published,
                publishedAt: Date.now(),
                publishedBy: organizationData.publishedBy
            },
            newTeamConf ? [...newTeamConf.map(conf => conf.id)] : []
        );
        history.push(`/team/${newID.data}`);
    };

    const canDeleteBoysTeam = Object.values(boysTeam?.dualMeets || {}).flat().length === 0;
    const canDeleteGirlsTeam = Object.values(girlsTeam?.dualMeets || {}).flat().length === 0;
    const canDeleteBoysJVTeam = Object.values(boysTeamJV?.dualMeets || {}).flat().length === 0;
    const canDeleteGirlsJVTeam = Object.values(girlsTeamJV?.dualMeets || {}).flat().length === 0;

    const submitOrganizationRequest = () => {
        setRequestLoading(true);
        DB.requestOrganizationPublication(requestingTakeover, id, organizationData.name, requestName, requestSchool, requestAddInf).then(
            () => {
                setRequestLoading(false);
                setRequestingOrganization(false);
                setRequestingTakeover(false);
            }
        );
    };

    const requestState = (() => {
        if (Object.values(organizationRequests).some(l => l.orgId === id)) {
            return "pending";
        } else if (organizationData.published) {
            return "published";
        }
        return "unpublished";
    })();

    const superAdmin = Boolean((userInfo?.flags || 0) & UserFlag.SuperAdmin);
    const canAdministrate = organizationData.administrators.includes(userInfo?.id || "") || (userInfo?.flags || 0) & UserFlag.SuperAdmin;
    const canTakeOver = !organizationData.administrators.length && Boolean(userInfo);

    const TakeOverCard = () => {
        return (
            <Card sx={{ maxWidth: "500px", width: "90%", margin: "auto" }}>
                <CardHeader title="Unmanaged School Notice" />
                <CardContent sx={{ padding: "20px !important" }}>
                    <Typography variant="body1">
                        This school was created as a write-in school for a college event. It is not currently being administrated and is
                        open for management. If you are a coach or manager at this school, you can request to administrate this school with
                        the button below.
                    </Typography>

                    <Button
                        variant="contained"
                        style={{ marginTop: "10px" }}
                        onClick={() => setRequestingTakeover(true)}
                        disabled={requestState === "pending"}
                    >
                        {requestState === "pending" ? "Management Request Pending..." : "Request Management"}
                    </Button>
                </CardContent>
            </Card>
        );
    };

    return (
        <TbTPage className="organizationDetailPage">
            <div style={{ height: 50 }}></div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                {organizationData.logoPath && (
                    <img
                        style={{
                            objectFit: "cover",
                            width: 128,
                            height: 128,
                            display: "block",
                            marginRight: 16
                        }}
                        src={
                            justUploadedLogo ??
                            `https://firebasestorage.googleapis.com/v0/b/touchbytouch-b336e.appspot.com/o/${encodeURIComponent(organizationData.logoPath)}?alt=media`
                        }
                    />
                )}
                <div>
                    <h1>{organizationData.name}</h1>
                    <h3>{NCAADivisions.find(l => l.id === organizationData.region)?.name || ""}</h3>
                    <h5>{NCAARegions.find(l => l.id === organizationData.district)?.name || ""}</h5>
                </div>
            </div>

            {canAdministrate && organizationUser ? (
                <h6 style={{ marginTop: 12 }}>
                    Created by {organizationUser.firstName} {organizationUser.lastName} (
                    <a href={`mailto:${organizationUser.email}`}>{organizationUser.email}</a>)
                </h6>
            ) : null}
            {canTakeOver && <TakeOverCard />}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "20px auto"
                }}
            >
                {!organizationData.published && canAdministrate && (
                    <Button
                        sx={{ margin: "0 8px" }}
                        variant="outlined"
                        disabled={requestState !== "unpublished"}
                        onClick={() => setRequestingOrganization(true)}
                    >
                        {requestState === "pending" ? "Publication Request Pending..." : "Request School Publication"}
                    </Button>
                )}
                {canAdministrate ? (
                    <Button sx={{ margin: "0 8px" }} variant="outlined" onClick={() => setConfigOpen(true)}>
                        Configure School
                    </Button>
                ) : null}
                {superAdmin && organizationData.administrators.length ? (
                    <Button sx={{ margin: "0 8px" }} onClick={() => setMakingUnmanaged(true)}>
                        Make unmanaged
                    </Button>
                ) : null}
            </Box>
            {organizationData.boysTeam && (
                <div className="organizationDetailDiv">
                    <TeamCard id={organizationData.boysTeam} />
                </div>
            )}
            {organizationData.girlsTeam && (
                <div className="organizationDetailDiv">
                    <TeamCard id={organizationData.girlsTeam} />
                </div>
            )}
            {organizationData.boysJVTeam && (
                <div className="organizationDetailDiv">
                    <TeamCard id={organizationData.boysJVTeam} />
                </div>
            )}
            {organizationData.girlsJVTeam && (
                <div className="organizationDetailDiv">
                    <TeamCard id={organizationData.girlsJVTeam} />
                </div>
            )}
            <Modal
                open={requestingOrganization || requestingTakeover}
                onClose={() => {
                    setRequestingOrganization(false);
                    setRequestingTakeover(false);
                }}
            >
                <Box sx={modalBoxStyle}>
                    <h2>Request {requestingTakeover ? "Management of School" : "School Publication"}</h2>
                    {requestingTakeover ? (
                        <h6>
                            Until your school is published, other users cannot see your school. Use this form below and provide your details
                            in order to get accepted. You will be sent an email shortly when your application is accepted or denied.
                        </h6>
                    ) : (
                        <h6>
                            Until your request is accepted, you will not be able to manage this school. Use this form below and provide your
                            details in order to get accepted. You will be sent an email shortly when your application is accepted or denied.
                        </h6>
                    )}
                    <FormControl style={{ marginBottom: 20, width: "100%" }}>
                        <TextField
                            label="Full Name"
                            placeholder="Full Name"
                            value={requestName}
                            onChange={e => setRequestName(e.target.value)}
                            variant="filled"
                            fullWidth
                            required
                        />
                    </FormControl>

                    <FormControl style={{ marginBottom: 20, width: "100%" }}>
                        <TextField
                            label="School"
                            placeholder="Enter your school"
                            value={requestSchool}
                            onChange={e => setRequestSchool(e.target.value)}
                            variant="filled"
                            fullWidth
                            required
                        />
                    </FormControl>

                    <FormControl style={{ marginBottom: 20, width: "100%" }}>
                        <TextField
                            label="Additional Information"
                            placeholder="Enter any additional information here"
                            value={requestAddInf}
                            onChange={e => setRequestAddInf(e.target.value)}
                            variant="filled"
                            fullWidth
                            multiline
                            minRows={3}
                        />
                    </FormControl>

                    <Button
                        onClick={submitOrganizationRequest}
                        disabled={!requestName || !requestSchool || requestLoading}
                        className={!requestName || !requestSchool || requestLoading ? "disabledButton" : ""}
                        variant="contained"
                        color="primary"
                    >
                        {requestLoading ? "Submitting..." : "Submit"}
                    </Button>
                </Box>
            </Modal>
            <Modal open={configOpen} onClose={() => setConfigOpen(false)}>
                <Box sx={modalBoxStyle}>
                    <Typography variant="h5" component="h5">
                        Configure School
                    </Typography>

                    <Box className="configRow">
                        <Typography variant="body1" style={{ marginRight: "0.5rem" }}>
                            This school is currently {organizationData.published ? "published" : "unpublished"}.
                        </Typography>
                        <Button onClick={() => (organizationData.published ? setUnpublishingOpen : setRequestingOrganization)(true)}>
                            {organizationData.published ? "Unpublish" : "Publish"}
                        </Button>
                    </Box>

                    <hr />

                    {organizationData.logoPath && (
                        <img
                            style={{
                                objectFit: "cover",
                                width: 128,
                                height: 128,
                                display: "block",
                                marginBottom: 12
                            }}
                            src={`https://firebasestorage.googleapis.com/v0/b/touchbytouch-b336e.appspot.com/o/${encodeURIComponent(organizationData.logoPath)}?alt=media`}
                        />
                    )}

                    <div style={{ display: "flex" }}>
                        <InputFileUpload onChange={previewSchoolLogo} text={organizationData.logoPath ? "Update" : "Upload Logo"} />
                        {organizationData.logoPath && (
                            <Button
                                component="label"
                                variant="contained"
                                color="error"
                                sx={{ marginLeft: 2 }}
                                onClick={() => setDeletingLogo(true)}
                            >
                                Remove
                            </Button>
                        )}
                    </div>
                    <div style={{ marginTop: 8, opacity: 0.75, fontSize: 12 }}>
                        Logo should be square and approximately 128&times;128 pixels
                    </div>

                    <hr />
                    <div className="configRow">
                        <Typography variant="body1">Men's {boysTeam?.region === "club" ? "Club" : "Varsity"} Team:</Typography>
                        {organizationData.boysTeam ? (
                            <Button
                                onClick={() => setDeleting("mens")}
                                disabled={!canDeleteBoysTeam}
                                variant="outlined"
                                style={{ margin: "0 5px 0 10px" }}
                            >
                                Delete
                            </Button>
                        ) : (
                            <Button
                                onClick={() => showCreateTeamDialogIfNeeded("boys")}
                                variant="contained"
                                style={{ margin: "0 5px 0 10px" }}
                            >
                                Create
                            </Button>
                        )}
                        <Button variant="text" onClick={() => startConversion("mens")}>
                            {boysTeam?.region === "club" ? "Convert to Varsity" : "Convert to Club"}
                        </Button>
                    </div>
                    <div className="configRow">
                        <Typography variant="body1">Women's {girlsTeam?.region === "club" ? "Club" : "Varsity"} Team:</Typography>
                        {organizationData.girlsTeam ? (
                            <Button
                                onClick={() => setDeleting("womens")}
                                disabled={!canDeleteGirlsTeam}
                                variant="outlined"
                                style={{ margin: "0 5px 0 10px" }}
                            >
                                Delete
                            </Button>
                        ) : (
                            <Button
                                onClick={() => showCreateTeamDialogIfNeeded("girls")}
                                variant="contained"
                                style={{ margin: "0 5px 0 10px" }}
                            >
                                Create
                            </Button>
                        )}
                        <Button variant="text" onClick={() => startConversion("womens")}>
                            {girlsTeam?.region === "club" ? "Convert to Varsity" : "Convert to Club"}
                        </Button>
                    </div>
                    <div className="configRow">
                        <Typography variant="body1">Men's JV Team:</Typography>
                        {organizationData.boysJVTeam ? (
                            <Button
                                onClick={() => setDeleting("mensJV")}
                                disabled={!canDeleteBoysJVTeam}
                                variant="outlined"
                                style={{ margin: "0 5px 0 10px" }}
                            >
                                Delete
                            </Button>
                        ) : (
                            <Button
                                onClick={() => showCreateTeamDialogIfNeeded("boysJV")}
                                variant="contained"
                                style={{ margin: "0 5px 0 10px" }}
                            >
                                Create
                            </Button>
                        )}
                    </div>
                    <div className="configRow">
                        <Typography variant="body1">Women's JV Team:</Typography>
                        {organizationData.girlsJVTeam ? (
                            <Button
                                onClick={() => setDeleting("womensJV")}
                                disabled={!canDeleteGirlsJVTeam}
                                variant="outlined"
                                style={{ margin: "0 5px 0 10px" }}
                            >
                                Delete
                            </Button>
                        ) : (
                            <Button
                                onClick={() => showCreateTeamDialogIfNeeded("girlsJV")}
                                variant="contained"
                                style={{ margin: "0 5px 0 10px" }}
                            >
                                Create
                            </Button>
                        )}
                    </div>
                    <hr />
                    <div className="configRow">
                        <Typography variant="body1" sx={{ whiteSpace: "nowrap", marginRight: "0.5rem" }}>
                            Region:
                        </Typography>
                        <FormControl fullWidth size="small">
                            <InputLabel id="region-select">Region</InputLabel>
                            <Select
                                labelId="region-select"
                                id="region-select"
                                label="Region"
                                value={region?.id}
                                onChange={e => setRegion(NCAARegions.find(l => l.id === e.target.value) || defaultRegion)}
                            >
                                {NCAARegions.map(l => (
                                    <MenuItem value={l.id} key={`setRegion${l.id}`}>
                                        {l.name || "Select region"}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    {organizationData.boysTeam && (
                        <div className="configRow">
                            <Typography variant="body1" sx={{ whiteSpace: "nowrap", marginRight: "0.5rem" }}>
                                Men's Conference:
                            </Typography>
                            <FormControl fullWidth size="small">
                                <InputLabel id="region-mens-select">Conference</InputLabel>
                                <Select
                                    labelId="region-mens-select"
                                    id="region-mens-select"
                                    label="Conference"
                                    value={boysTeamConf.map(conf => conf.id)}
                                    multiple
                                    renderValue={selected => (
                                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                            {selected.map(value => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                    onChange={handleMensConferenceSelectChange}
                                >
                                    {NCAAMensConferences.map(l => (
                                        <MenuItem value={l.id} key={`setMenTeamConf${l.id}`}>
                                            <Checkbox checked={boysTeamConf.includes(l)} />
                                            {l.name || "Select conference"}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    )}
                    {organizationData.girlsTeam && (
                        <div className="configRow">
                            <Typography variant="body1" sx={{ whiteSpace: "nowrap", marginRight: "0.5rem" }}>
                                Women's Conference:
                            </Typography>
                            <FormControl fullWidth size="small">
                                <InputLabel id="region-womens-select">Conference</InputLabel>
                                <Select
                                    labelId="region-womens-select"
                                    id="region-womens-select"
                                    label="Conference"
                                    value={girlsTeamConf.map(conf => conf.id)}
                                    multiple
                                    renderValue={selected => (
                                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                            {selected.map(value => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                    onChange={handleWomensConferenceSelectChange}
                                >
                                    {NCAAWomensConferences.map(l => (
                                        <MenuItem value={l.id} key={`setWomenTeamConf${l.id}`}>
                                            <Checkbox checked={girlsTeamConf.includes(l)} />
                                            {l.name || "Select conference"}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    )}
                    <Button onClick={updateConferences} variant="contained">
                        Update
                    </Button>
                </Box>
            </Modal>
            <Dialog open={Boolean(creating)} onClose={() => setCreating("")}>
                <Box style={{ padding: 20, minWidth: 300 }}>
                    <Typography variant="h5" component="h5">
                        Create team
                    </Typography>
                    <FormControl fullWidth size="small" sx={{ margin: "20px 0" }}>
                        <InputLabel id="conference-select">Conference Name</InputLabel>
                        <Select
                            fullWidth
                            labelId="conference-select"
                            id="conference-select"
                            label="Conference Name"
                            value={newTeamConf.map(conf => conf.id)}
                            multiple
                            renderValue={selected => (
                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                    {selected.map(value => (
                                        <Chip key={value} label={value} />
                                    ))}
                                </Box>
                            )}
                            onChange={handleNewTeamConferenceSelectChange}
                        >
                            {(creating.includes("girls") ? NCAAWomensConferences : NCAAMensConferences).map(l => (
                                <MenuItem value={l.id} key={`newTeamConference${l.id}`}>
                                    <Checkbox checked={newTeamConf.some(j => j.id === l.id)} />
                                    {l.name || "Select conference"}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <br />
                    <Button variant="contained" onClick={createTeam}>
                        Create
                    </Button>
                </Box>
            </Dialog>
            <Dialog open={Boolean(converting)} onClose={() => setConverting("")}>
                <Box style={{ padding: 20, minWidth: 300 }}>
                    <Typography variant="h5" component="h5">
                        Convert team
                    </Typography>
                    <FormControl fullWidth size="small" sx={{ margin: "20px 0" }}>
                        <InputLabel id="region-select">Division Name</InputLabel>
                        <Select
                            fullWidth
                            labelId="region-select"
                            id="region-select"
                            label="Division Name"
                            value={convertingRegion}
                            onChange={e => setConvertingRegion(e.target.value || convertingTeam?.region || "")}
                        >
                            {NCAADivisions.map(l => (
                                <MenuItem value={l.id} key={`newTeamDivision${l.id}`}>
                                    {l.name || "Select division"}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <br />
                    <Button variant="contained" onClick={() => convert()}>
                        Convert
                    </Button>
                </Box>
            </Dialog>
            <Dialog open={unpublishingOpen} onClose={() => setUnpublishingOpen(false)}>
                <DialogTitle>Unpublish school</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This school will no longer be visible to the public. If you want to republish this school later, you will need to
                        fill out another publication request.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={() => setUnpublishingOpen(false)}>
                        Cancel
                    </Button>
                    <Button variant="text" onClick={() => unpublish()}>
                        Unpublish
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={Boolean(deleting)} onClose={() => setDeleting("")}>
                <DialogTitle>Delete Team</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete this team? This cannot be undone!</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={() => setDeleting("")}>
                        Cancel
                    </Button>
                    <Button variant="text" onClick={() => deleteTeam()}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={makingUnmanaged} onClose={() => setMakingUnmanaged(false)}>
                <DialogTitle>Make this school unmanaged?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This school will no longer have any administrators. Users will be able to make takeover requests for this team.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setMakingUnmanaged(false)}>Cancel</Button>
                    <Button onClick={() => makeUnmanaged()}>Confirm</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={Boolean(previewLogo)} onClose={() => setPreviewLogo(undefined)}>
                <DialogTitle>Upload Preview</DialogTitle>
                <DialogContent sx={{ paddingBottom: 1 }}>
                    <img
                        style={{
                            objectFit: "cover",
                            width: 128,
                            height: 128
                        }}
                        src={previewLogo}
                    />
                </DialogContent>
                <DialogActions
                    sx={{
                        paddingX: 3,
                        paddingBottom: 2
                    }}
                >
                    <Button variant="contained" onClick={uploadSchoolLogo} sx={{ marginRight: 2 }}>
                        Confirm
                    </Button>
                    <Button variant="contained" color="error" onClick={() => setPreviewLogo(undefined)}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={Boolean(deletingLogo)} onClose={() => setDeletingLogo(false)}>
                <DialogTitle>Delete Logo</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete the logo? This cannot be undone!</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={() => setDeletingLogo(false)}>
                        Cancel
                    </Button>
                    <Button variant="text" onClick={() => removeSchoolLogo()}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <SnackbarError error={snackbarError} close={() => setSnackbarError("")} />
        </TbTPage>
    );
}
